/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {
    .header .hamburger-menu {
        display: block;
        padding: 10px;
        padding-left: 0;
    }

    .header .hamburger-menu svg {
        width: 1.8rem;
        height: 1.8rem;
    }

    #sidebar-nav {
        position: fixed;
        height: 100vh;
        /* width: 50%; */
        top: 0;
        left: -100%;
        transition: 500ms;
    }

    #sidebar-nav.active {
        left: 0;
        transition: 500ms;
        width: 300px !important;
    }

    #sidebar-nav .llm-logo-sidebar {
        display: none;
    }

    #sidebar-nav .close-responsive {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 0;
    }

    .close-responsive svg {
        position: relative;
        right: 25px;
    }

    #content {
        margin-left: 0 !important;
        width: unset;
    }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .header .hamburger-menu {
        display: block;
        padding: 10px;
        padding-left: 0;
    }

    .header .hamburger-menu svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    /* .header .dd-menu {
        width: 320px;
    } */

    .header .dropdown-content-header,
    .header .dropdown-content-info {
        padding: 10px;
    }

    .dropdown-item .dropdown-text .dropdown-content-info-text {
        font-size: 12px;
        font-weight: 500;
        width: unset;
    }

    .pp-info.dropdown-item,
    .pp-settings.dropdown-item {
        font-size: 12px;
        padding: 0;
    }

    .dd-menu .btn-link.reminder-btn {
        font-size: 12px;
    }

    .dd-noti-item .dropdown-content-info svg {
        width: 1rem;
        height: 1rem;
    }

    #sidebar-nav {
        position: fixed;
        height: 100vh;
        /* width: 300px; */
        top: 0;
        left: -100%;
        transition: 500ms;
    }

    #sidebar-nav.active {
        left: 0;
        transition: 500ms;
        width: 300px !important;
    }

    #sidebar-nav .llm-logo-sidebar {
        display: none;
    }

    #sidebar-nav .close-responsive {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 0;
    }

    .close-responsive svg {
        position: relative;
        right: 25px;
    }

    #content {
        margin-left: 0 !important;
        width: 100vw;
        min-width: fit-content;
    }

    #content .header {
        padding: 5px 30px;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {
    .header .hamburger-menu {
        display: block;
        padding: 10px;
        padding-left: 0;
    }

    .header .hamburger-menu svg {
        width: 1.5rem;
        height: 1.5rem;
    }

    .header .dd-menu {
        width: 320px;
    }

    .header .dropdown-content-header,
    .header .dropdown-content-info {
        padding: 10px;
    }

    .dropdown-item .dropdown-text .dropdown-content-info-text {
        font-size: 12px;
        font-weight: 500;
        width: unset;
    }

    .pp-info.dropdown-item,
    .pp-settings.dropdown-item {
        font-size: 12px;
        padding: 0;
    }

    .dd-menu .btn-link.reminder-btn {
        font-size: 12px;
    }

    .dd-noti-item .dropdown-content-info svg {
        width: 1rem;
        height: 1rem;
    }

    #sidebar-nav {
        position: fixed;
        height: 100vh;
        /* width: 300px; */
        top: 0;
        left: -100%;
        transition: 500ms;
    }

    #sidebar-nav.active {
        left: 0;
        transition: 500ms;
        width: 300px !important;
    }

    #sidebar-nav .llm-logo-sidebar {
        display: none;
    }

    #sidebar-nav .close-responsive {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px 0;
    }

    .close-responsive svg {
        position: relative;
        right: 25px;
    }

    #content {
        margin-left: 0 !important;
        width: 100vw;
        min-width: fit-content;
    }

    #content .header {
        padding: 5px 30px;
    }
}

.close-responsive {
    display: none;
}

.dd-noti-item .dropdown-content-info svg {
    width: 1.2rem;
    height: 1.1rem;
}