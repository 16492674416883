/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {
    .row-upload-pic.row .col-4 {
        width: 50%;
    }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .nav-container-nf .nav.nav-tabs.nav-justified {
        width: 100%;
    }

    .row-upload-pic.row {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }

    .row-upload-pic.row .img-box {
        padding-bottom: 10px;
    }

    .row-upload-pic.row .col-4 {
        width: 100%;
    }

    .personal-details.row {
        display: flex;
        flex-direction: column;
    }

    .personal-details.row .col-6 {
        width: 100%;
    }

    /* Change Password */
    .chg-pw.row {
        display: flex;
        flex-direction: column;
    }

    .chg-pw.row .col-6 {
        width: 100%;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) { 

    .nav-container-nf .nav.nav-tabs.nav-justified {
        width: 100%;
    }

    .nav-container-nf .nav-link {
        font-size: 12px;
    }

    .row-upload-pic.row {
        display: flex;
        flex-direction: column;
        align-items: unset;
    }

    .row-upload-pic.row .img-box {
        padding-bottom: 10px;
    }

    .row-upload-pic.row .col-4 {
        width: 100%;
    }

    .row-upload-pic.row .text-muted {
        font-size: 12px;
    }

    #personal-form .form-label {
        font-size: 12px;
    }

    .personal-details.row {
        display: flex;
        flex-direction: column;
    }

    .personal-details.row .col-6 {
        width: 100%;
    }

    /* Change Password */
    #password-staf-form .form-label {
        font-size: 12px;
    }

    .chg-pw.row {
        display: flex;
        flex-direction: column;
    }

    .chg-pw.row .col-6 {
        width: 100%;
    }
}

.nav-container-nf .nav.nav-justified {
    width: 50%;
}