/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .share-empty .meeting-empty.media svg {
        height: 4rem;
        width: 4rem;
    }

    .share-empty .meeting-empty .meeting-empty-wording {
        font-size: 14px;
    }
}

.share-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.share-empty .meeting-empty svg {
    height: 6rem;
    width: 6rem;
}

.share-empty .meeting-empty-wording {
    font-size: 18px;
    padding: 0;
}