@import '../../constants/theme.css';

/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
  .pagination.page-cont button:active {
    transform: unset;
  }

  .pagination.page-cont button:hover {
    transform: unset;
    background-color: #FFF;
    color: #000;
  }

  .list-cand .table-list thead {
    display: none;
  }

  .list-cand .table-list,
  .list-cand .table-list tbody,
  .list-cand .table-list tr,
  .list-cand .table-list td {
    display: block;
    width: 100%;
    background: unset;
  }

  .list-cand .table-list tr {
    background-color: #FFF;
    margin-top: 15px;
  }

  .list-cand .table-list tr:nth-child(1) {
    margin-top: 0px;
  }

  .list-cand .table-list td:nth-child(even) {
    background-color: #E5E5E5;
  }

  .list-cand .table-list td {
    /* text-align: right; */
    padding-left: 50%;
    position: relative;
    /* font-size: 12px; */
    /* padding-bottom: 20px; */
  }

  .list-cand .table-list td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    /* font-size: 12px; */
    text-align: left;
    font-weight: 600;
  }

  /* Border radius for first column in table */
  .list-cand .table-list td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    /* background-color: rebeccapurple; */
  }

  /* Border radius for last column in table */
  .list-cand .table-list td:last-child {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    /* background-color: brown; */
  }

  .pagination.page-cont button:active {
    transform: unset;
  }

  .pagination.page-cont button:hover {
    transform: unset;
    background-color: #FFF;
    color: #000;
  }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {
  .list-cand .table-list thead {
    display: none;
  }

  .list-cand .table-list,
  .list-cand .table-list tbody,
  .list-cand .table-list tr,
  .list-cand .table-list td {
    display: block;
    width: 100%;
    background: unset;
  }

  .list-cand .table-list tr {
    background-color: #FFF;
    margin-top: 15px;
  }

  .list-cand .table-list tr:nth-child(1) {
    margin-top: 0px;
  }

  .list-cand .table-list td:nth-child(even) {
    background-color: #E5E5E5;
  }

  .list-cand .table-list td {
    /* text-align: right; */
    padding-left: 50%;
    position: relative;
    font-size: 12px;
    /* padding-bottom: 20px; */
  }

  .list-cand .table-list td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-size: 12px;
    text-align: left;
    font-weight: 600;
  }

  /* Border radius for first column in table */
  .list-cand .table-list td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    /* background-color: rebeccapurple; */
  }

  /* Border radius for last column in table */
  .list-cand .table-list td:last-child {
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    /* background-color: brown; */
  }

  .pagination.page-cont button {
    padding: 8px 10px;
    /* margin: 0; */
  }

  .pagination.page-cont button:active {
    transform: unset;
  }

  .pagination.page-cont button:hover {
    transform: unset;
    background-color: #FFF;
    color: #000;
  }

  .pagination span {
    font-size: 12px;
  }

  .pagination .go-to-page {
    display: none;
  }

  .pagination input {
    display: none;
  }

  .pagination select {
    font-size: 12px;
  }
}

/* Custom Table CSS */
.table-list {
  width: 100%;
  /* height: auto; */
  border-spacing: 0 10px;
  border-collapse: separate;
  /* background-color: red; */
}

.table-list_tblfunction {
  width: 100%;
  /* height: auto; */
  /* border-spacing: 0 10px; */
  /* border-collapse: separate; */
}

.db-stats-row .header_text {
  /* word-break: inherit; */
}

.header_text {
  font-size: var(--fs-tbl-header);
  font-weight: var(--fw-default);
  line-height: 21px;
  color: var(--text-color);
  /* background-color: blue; */
  word-break: normal;
}

.header_text_tblfunction {
  font-size: var(--fs-tbl-header);
  font-weight: var(--fw-default);
  line-height: 21px;
  color: var(--text-color);
  background-color: var(--default);
  border: 1px solid lightgray;
  padding: 10px;
}

.tbody {
  background-color: var(--table-background-tbody);
}

.trow {
  /* border-collapse: none; */
  box-shadow: 0px 2px 4px var(--table-box-shadow);
  border-radius: 10px;
}

.trow.allNoti td {
  padding-top: 15px;
  padding-bottom: 15px;
}

.trow_tblfunction {
  border-collapse: none;
  border: 1px solid lightgray;
  /* box-shadow: 0px 2px 4px var(--table-box-shadow); */
  /* border-radius: 10px; */
}

.body_text {
  font-size: var(--fs-tbl-body);
  font-weight: 400;
  line-height: 21px;
  color: var(--text-default);
  word-break: break-word;
  /* background-color: yellowgreen; */
}

.body_text_tblfunction {
  font-size: var(--fs-tbl-body);
  font-weight: 400;
  line-height: 21px;
  color: var(--text-default);
  border: 1px solid lightgray;
}

/* Border radius for first column in table */
td:first-child {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* background-color: rebeccapurple; */
}

/* Border radius for last column in table */
td:last-child {
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  /* background-color: brown; */
}

.table-list th,
td {
  text-align: left;
  padding: 10px 15px;
}

.more {
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
}

.more span {
  width: .10rem;
  height: .40rem;
  background-color: transparent;
  justify-content: center;
  border-radius: 50%;
  pointer-events: none;
  background-color: black;
  display: flex;
  justify-content: center;
  margin: 2px;
}

.dropout {
  z-index: 9001;
  width: 100%;
  /* position: absolute; */
  margin-left: auto;
  margin-right: auto;
}

.button-container {
  padding: 0;
  overflow: hidden;
  display: flex;
  /*Code Added */
  justify-content: center;
  margin-left: auto;
  font-size: 40px;
  background-color: transparent;

}

/* Pagination */
.pagination {
  display: flex;
  justify-content: end;
  align-content: center;
  /* background-color: red; */
  margin-top: 15px;
  color: black;
}

.pagination button {
  background-color: #FFF;
  padding: 5px 10px;
  margin: 5px;
  border: none;
  box-shadow: 0 8px 16px 0 var(--box-shadow);
  font-weight: var(--fw-btn-default);
  font-size: var(--fs-btn-default);
  border-radius: 5px;
  color: black;
}

.pagination span {
  padding: 0 10px;
  /* padding-left: 0; */
  /* margin: 1px; */
  /* background-color: red; */
  display: flex;
  align-items: center;
}

.pagination button:hover {
  background-color: var(--pagination-bgcolor);
  transform: translateY(-3px);
  color: #FFF;
}

.pagination button:active {
  background-color: #c0c0c0;
  box-shadow: 0 1px #666;
  transform: translateY(2px);
}

.pagination input {
  background-color: #FFF;
  padding: 5px;
  margin: 5px;
  border: none;
  box-shadow: 0 8px 16px 0 var(--box-shadow);
  font-weight: bold;
  border-radius: 5px;
  border-style: hidden;
  width: 5vw;
}

.pagination input:focus {
  box-shadow: 0 0 10px var(--pagination-bgcolor);
  border-style: hidden;
}

.pagination select {
  background-color: #FFF;
  padding: 5px;
  margin: 5px;
  border: none;
  box-shadow: 0 8px 16px 0 var(--box-shadow);
  font-weight: bold;
  border-radius: 5px;
  border-style: hidden;
}

.pagination select:focus {
  box-shadow: 0 0 10px var(--pagination-bgcolor);
  border-style: hidden;
}

input[type="checkbox"] {
  width: 15px;
  height: 15px;
  padding: 6px;
  /* background-color only for content */
  /* background-clip: content-box; */
}

input[type="checkbox"]:checked {
  color: red
}

.dropbtn-table {
  background-color: transparent;
  color: black;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropup {
  position: relative;
  display: inline-block;
  z-index: 1;

}

.dropup-content-table {
  display: none;
  position: absolute;
  background-color: var(--white);
  box-shadow: 0px 5px 10px 0px var(--box-shadow);
  min-width: 190px;
  bottom: 50px;
  z-index: 1;
  right: 0;
  bottom: 3;
}

.dropup-content-table a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropup-content-table a:hover {
  background-color: var(--default-color);
  color: var(--white);
}

.dropup:hover .dropup-content-table {
  display: block;
}

.dropup:hover .dropbtn-table {
  background-color: var(--menu-background);
  border-radius: 10px;
}