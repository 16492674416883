/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {
    #filter-cand-form .form-container-filter .col-4 {
        width: 49.4% !important;
    }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .cdt-page .filter_container_search {
        padding: 0;
    }

    .cdt-page .form-container-filter-button .btn {
        display: flex;
    }

    .cdt-page .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
    }

    .collapses.in {
        margin-bottom: 20px;
    }

    #filter-cand-form .form-container-filter {
        flex-direction: column;
    }

    #filter-cand-form .form-container-filter .col-4 {
        width: 100% !important;
    }

    .table-list .checkbox-container {
        justify-content: flex-start;
    }

    .dropdown-container .dropdown {
        display: contents;
    }

    .dropdown-container.list-cand .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    .list-cand .col-3 {
        width: 40%;
    }

    .list-cand .col-5 {
        width: 50%;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {

    .cdt-page .filter_container_search {
        padding: 0;
    }

    .cdt-page .form-container-filter-button .btn {
        display: flex;
        padding: 8px 15px;
    }

    .cdt-page .filter_container_typelist .form-container-filter-button .btn svg {
        height: 1rem;
        width: 1rem;
        margin: 0;
    }

    .cdt-page .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
    }

    .cdt-page .form-container-filter-button .btn div {
        display: none;
    }

    .collapses.in {
        margin-bottom: 20px;
    }

    #filter-cand-form .form-container-filter .col-4 {
        width: 100% !important;
    }

    #filter-cand-form .form-container-filter {
        flex-direction: column;
    }

    #filter-cand-form .form-label {
        font-size: 12px;
    }

    .table-list .checkbox-container {
        justify-content: flex-start;
    }

    .container-div.list-cand .btn-link.no-underline {
        font-size: 12px;
    }

    .dropdown-container .dropdown {
        display: contents;
    }

    .dropdown-container .dropdown-item {
        font-size: 12px;
    }

    .dropdown-container.list-cand .dropdown-item svg {
        height: 1rem;
        width: 1rem;
    }

    .dropdown-container.list-cand .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    /* View Candidate Profile */
    .list-cand .tab-header-font {
        font-size: 14px;
    }

    .list-cand .right-div.two-btn .btn {
        padding: 0;
    }

    .list-cand .text-col {
        font-size: 12px;
        padding: 0px;
        font-weight: 700;
    }

    .list-cand .card-education {
        flex-direction: column;
    }

    .list-cand .card-education__row-flex5 .sub-text,
    .list-cand .mini-text,
    .candidate-status-div .candidate-status {
        font-size: 12px;
    }

    .list-cand .card-edu-btn {
        margin: 0;
        justify-content: flex-end;
    }

    .both-side .btn {
        font-size: 12px;
    }

    .both-side .title-text {
        margin-bottom: 0;
    }

    /* Add Interview */
    #inv-form .btn {
        font-size: 12px;
    }
}

#filter-cand-form .form-container-filter .col-4 {
    width: 32.8%;
  }

.dropdown-container .dropdown-item svg {
    height: 1.2rem;
    width: 1.2rem;
}

.cdt-page .form-container-filter-button .btn {
    display: flex;
}

.cdt-page .form-container-filter-button .btn svg {
    height: 1.2rem;
    width: 1.2rem;
}