/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .list-opt .filter-container-dropdown .form-select {
        padding: 8px 15px;
    }


    .list-opt .filter_container .btn {
        display: flex;
        padding: 8px 15px;
    }

    .dropdown-container.list-opt .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    .list-opt .table-list thead {
        display: none;
    }

    .list-opt .table-list,
    .list-opt .table-list tbody,
    .list-opt .table-list tr,
    .list-opt .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-opt .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-opt .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-opt .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-opt .table-list td {
        /* text-align: right; */
        padding-left: 50%;
        position: relative;
        /* font-size: 12px; */
        /* padding-bottom: 20px; */
    }

    .list-opt .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        /* font-size: 12px; */
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-opt .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        /* background-color: rebeccapurple; */
    }

    /* Border radius for last column in table */
    .list-opt .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        /* background-color: brown; */
    }
}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .list-opt .filter-container-dropdown .form-label {
        font-size: 12px;
    }

    .list-opt .filter-container-dropdown .form-select {
        padding: 8px 15px;
    }


    .list-opt .filter_container .btn {
        display: flex;
        padding: 8px 15px;
    }

    .list-opt .filter_container .btn svg {
        height: 1rem;
        width: 1rem;
        margin: 0;
    }

    .list-opt .filter_container .btn div {
        display: none;
    }

    .dropdown-container.list-opt .dropdown-item svg {
        height: 1rem;
        width: 1rem;
    }

    .dropdown-container.list-opt .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    .list-opt .table-list thead {
        display: none;
    }

    .list-opt .table-list,
    .list-opt .table-list tbody,
    .list-opt .table-list tr,
    .list-opt .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-opt .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-opt .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-opt .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-opt .table-list td {
        padding-left: 50%;
        position: relative;
        font-size: 12px;
    }

    .list-opt .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-opt .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .list-opt .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
}

.list-opt .filter_container .btn {
    display: flex;
}

.list-opt .filter_container .btn svg {
    height: 1.2rem;
    width: 1.2rem;
}