@import '../../constants/theme.css';

/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .breadcrumb-div .breadcrumb-btn button {
        font-size: 18px;
    }
}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .breadcrumb-div .breadcrumb-btn button {
        font-size: 18px;
    }
}

.breadcrumb-div {
    display: flex;
    padding: 0;
    /* background-color: red; */
    margin-bottom: 0;
}

.breadcrumb-div li:nth-child(2) {
    order: 3;
}

.breadcrumb-div svg {
    order: 2;
    color: var(--main-color);
    height: 1.9rem;
}

.breadcrumb-btn button { 
    cursor: pointer;
    font-size: var(--fs-large);
    font-weight: var(--fw-default);
    font-family: var(--font-family);
    background-color: transparent;
    color: var(--text-color);
}

.breadcrumb-btn {
    list-style-type: none;
}

.disabled button {
    color: var(--default-color);
}