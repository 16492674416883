/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .list-placement .filter_container_totalrow {
        display: none;
    }

    .list-placement .upper-divider {
        display: none;
    }

    .list-placement .filter_container_search {
        padding: 0;
    }

    .list-placement .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
    }

    #filter-placement .form-container-filter {
        flex-direction: column;
    }

    .list-placement .table-list thead {
        display: none;
    }

    .list-placement .table-list,
    .list-placement .table-list tbody,
    .list-placement .table-list tr,
    .list-placement .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-placement .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-placement .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-placement .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-placement .table-list td {
        padding-left: 50%;
        position: relative;
    }

    .list-placement .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-placement .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .list-placement .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .list-placement .filter_container_totalrow {
        display: none;
    }

    .list-placement .upper-divider {
        display: none;
    }

    .list-placement .filter_container_search {
        padding: 0;
    }

    .list-placement .form-container-filter-button .btn {
        display: flex;
        padding: 8px 15px;
    }

    .list-placement .filter_container_typelist .form-container-filter-button .btn svg {
        margin: 0;
    }

    .list-placement .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
    }

    .list-placement .form-container-filter-button .btn div {
        display: none;
    }

    #filter-placement .form-container-filter {
        flex-direction: column;
    }

    .container-div.list-placement .btn-link.no-underline {
        font-size: 12px;
    }

    .list-placement .table-list thead {
        display: none;
    }

    .list-placement .table-list,
    .list-placement .table-list tbody,
    .list-placement .table-list tr,
    .list-placement .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-placement .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-placement .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-placement .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-placement .table-list td {
        padding-left: 50%;
        position: relative;
        font-size: 12px;
    }

    .list-placement .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-placement .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .list-placement .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
}

.list-placement .form-container-filter-button .btn {
    display: flex;
}