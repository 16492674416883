/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {
    .main-div.cal-div {
        /* padding: unset; */
        width: 200vw;
    }

    .calendar-cont .calendar-center-wording {
        font-size: 12px;
        line-height: unset;
        word-break: keep-all;
    }

    .btn.cal-btn {
        font-size: 12px;
        padding: 6px 16px;
    }

    .cal-div .btn.btn-danger {
        font-size: 12px;
        padding: 8px 15px;
    }

    .welcome-text.cal {
        font-size: 12px;
    }
}

.main-div.cal-div {
    height: 100%;
}

.welcome-text {
    font-size: 14px;
}

.fc {
    background-color: #FFF;
    padding: 30px;
    border-radius: 10px;
}

.fc .fc-toolbar-title {
    font-weight: 900;
    color: #212529;
}

.fc-scrollgrid-sync-inner a {
    color: #212529;
    font-weight: 400;
    text-decoration: none;
}

.fc .fc-daygrid-day-top {
    flex-direction: row;
}

.fc-daygrid-dot-event .fc-event-title {
    font-weight: 500;
    color: rgba(236, 120, 47, 0.8);
    font-size: 12px;
}

.fc .fc-daygrid-day-number {
    color: #212529;
}

.fc .fc-button-primary {
    background-color: rgba(236, 120, 47, 0.5);
    color: #212529;
    font-size: 14px;
    border: none;
    text-transform: capitalize;
}

.fc .fc-button-primary:disabled {
    background-color: #EC782F;
    color: #212529;
}

.fc .fc-button-primary:hover {
    background-color: #EC782F;
}

.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #EC782F;
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #EC782F;
}

.fc .fc-button-primary:focus {
    box-shadow: 0 0 0 0.2rem rgb(76 91 106 / 50%);
    /* background-color: #EC782F; */
}

.fc .fc-button:focus {
    outline: 0;
    box-shadow: none;
    /* background-color: #EC782F; */
}

.fc .fc-button-primary:not(:disabled):active:focus,
.fc .fc-button-primary:not(:disabled).fc-button-active:focus {
    box-shadow: none;
    background-color: #EC782F;
}

tr.fc-list-event.fc-event.fc-event-start.fc-event-end.fc-event-past,
tr.fc-list-event.fc-event.fc-event-start.fc-event-end {
    font-size: 14px;
}

a.fc-list-day-side-text,
a.fc-list-day-side-text:hover {
    text-decoration: none;
    color: #0d6efd;
}

.fc-event-main .fc-event-title {
    /* background-color: red; */
    font-size: 12px;
}

.popover-header {
    background-color: #EC782F;
    color: white;
}

.popover {
    max-width: 300px;
}

.spinner-calendar {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
    position: relative;
    top: 250px;
    left: 550px;
    z-index: 9;
}

.calendar-cont {
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 75vh;
}

.calendar-center-wording {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    color: rgba(0, 120, 212, 0.7);
    padding: 20px;
}

/* .spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
    position: absolute;
    top: 70%;
    left: 50%;
    z-index: 9;
} */

.spinner-calendar {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border;
    position: absolute;
    top: 73vh;
    left: 58vw;
    z-index: 9;
}

.calendar-overlay {
    background: #FFF;
    height: 100vh;
    width: 75%;
    opacity: 0.4;
    position: absolute;
    top: 145px;
    z-index: 9;
    border-radius: 10px;
}

.btn-primary:disabled {
    background-color: #bdbdbd;
    color: #212529;
}