/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {

    .auth-logo-div {
        display: none;
    }

    .auth-logo-resp {
        display: block !important;
    }

    .login-container {
        width: 100vw !important;
        height: 100% !important;
    }

    .left-welcome-div {
        display: none !important;
    }

    .right-login-form-div {
        /* background-color: yellow; */
        display: flex;
        height: 100%;
        align-items: inherit !important;
    }

    .auth-logo-resp {
        padding: 20px 0;
    }

    .title-form-section {
        /* background-color: red; */
        text-align: center;
    }

    .title-form-section .small-text {
        display: none;
    }

    .top-login-section {
        height: 100%;
    }

    .row-link-button.login {
        display: block !important;
    }

    .row-link-button.login .btn {
        padding: 0;
        font-size: 14px !important;
    }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .auth-logo-div {
        display: none;
    }

    .auth-logo-resp {
        display: block !important;
    }

    .login-container {
        width: 100vw !important;
        height: unset !important;
    }

    .auth-logo-resp {
        /* background-color: red; */
        padding: 20px 0;
        width: 100%;
        /* height: 100%; */
        margin: 0;
    }

    .auth-logo-resp img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 90%;
    }

    .left-welcome-div {
        display: none !important;
    }

    .right-login-form-div {
        /* background-color: yellow; */
        display: flex;
        /* height: 100%; */
        align-items: inherit !important;
    }

    .top-login-section {
        padding: 10px 40px !important;
        height: 100%;
    }

    .title-form-section {
        /* background-color: red; */
        text-align: center;
        margin-bottom: 0 !important;
    }

    .title-form-section .main-title {
        font-size: 6vw;
    }

    .title-form-section .small-text {
        display: none;
    }

    #login-form .form-label {
        display: none;
    }

    .row-link-button {
        flex-direction: column-reverse;
        margin-bottom: 0 !important;
        justify-content: center;
    }

    .row-link-button .btn {
        width: 100%;
        margin-bottom: 15px;
        padding: 8px 0;
        justify-content: center;
        font-size: 14px !important;
    }

    .row-link-button.login {
        display: block !important;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {

    .form-control {
        font-size: 12px !important;
    }

    .auth-logo-div {
        display: none;
    }

    .auth-logo-resp {
        display: block !important;
    }

    .login-container {
        width: 100vw !important;
        height: unset !important;
        /* height: 100% !important; */
    }

    .auth-logo-resp {
        /* background-color: red; */
        padding: 20px 0;
        width: 100%;
        /* height: 100%; */
        margin: 0;
    }

    .auth-logo-resp img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 90%;
    }

    .left-welcome-div {
        display: none !important;
    }

    .right-login-form-div {
        /* background-color: yellow; */
        display: flex;
        /* height: 100%; */
        align-items: inherit !important;
    }

    .top-login-section {
        padding: 10px 40px !important;
        height: 100%;
    }

    .title-form-section {
        /* background-color: red; */
        text-align: center;
        margin-bottom: 0 !important;
    }

    .title-form-section .main-title {
        font-size: 6vw;
    }

    .title-form-section .small-text {
        display: none;
    }

    .auth-footer.right {
        /* background-color: greenyellow; */
        /* justify-content: flex-end; */
        font-size: 12px;
    }

    #login-form .form-label {
        display: none;
    }

    ::placeholder {
        font-size: 10px !important;
    }

    .form-select {
        font-size: 12px !important;
    }

    .css-1gudy06-placeholder {
        font-size: 12px;
    }

    .row-link-button {
        flex-direction: column-reverse;
        margin-bottom: 0 !important;
        justify-content: center;
    }

    .row-link-button .btn {
        font-size: 12px;
        width: 100%;
        margin-bottom: 15px;
        padding: 8px 0;
        justify-content: center;
    }

    .row-link-button.login {
        display: block !important;
    }
}

.row-link-button.login {
    display: none;
}

.auth-logo-resp {
    display: none;
}

.auth-container {
    /* background: red; */
    height: 100vh;
    display: flex;
}

.login-container {
    /* background: yellow; */
    display: flex;
    width: 60vw;
    height: 80vh;
    margin: auto;
    border-radius: 10px;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.left-welcome-div {
    background: #EC782F;
    flex: 1;
    border-radius: 10px 0px 0px 10px;
    box-shadow: 2px 0 4px 0 rgba(0, 0, 0, 0.2);
    height: 100%;
    display: flex;
    /* if flex, cannot hide on responsive */
    align-items: center;
    justify-content: center;
}

.left-welcome-div>.left-overlay {
    background-image: url(https://limlogesmasters.com/wp-content/uploads/2021/08/app_bg.png);
    background-size: cover;
    opacity: 1;
    mix-blend-mode: luminosity;
    transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px 0px 0px 10px;
}

.right-login-form-div {
    /* background: brown; */
    flex: 2;
    border-radius: 0px 10px 10px 0px;
    /* display: flex; */
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.top-login-section {
    /* background-color: pink; */
    /* margin: auto 60px; */
    padding: 60px;
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.title-form-section {
    /* background-color: yellow; */
    /* margin: 20px 0; */
    margin-bottom: 20px;
}

.row-link-button {
    /* background-color: orange; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.row-link-button .btn--link {
    font-weight: 400;
    font-size: 14px;
}

.auth-footer {
    /* background-color: green; */
    display: flex;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    margin-bottom: 15px;
}

.auth-footer.reminder-btn .btn--link {
    color: #FFF;
    font-weight: 400;
}

.auth-footer.right .btn--link {
    color: #EC782F;
    font-weight: 600;
}

.auth-footer.right>* {
    margin-left: 5px;
}

.main-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 48px;
    color: #54595F;
}

.small-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    color: #FFF;
}

.mid-section>* {
    margin: 5px;
}

.main-div.admin-login {
    background-color: #EC782F;
}