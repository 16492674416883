@import '../../constants/theme.css';

/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .inputWithIcon .input {
        width: 100%;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {
    .inputWithIcon .input {
        width: 50vw;
    }
}

.input {
    background-color: #FFF;
    /* width: 500px; */
    border-radius: 10px;
    /* box-shadow: 0 8px 16px 0 var(--box-shadow); */
    border-style: solid;
    border-width: thin;
    border-color: var(--border-color);
    font-family: var(--font-style);
    padding: 3px 2px;

}

/* .is-invalid{
    background-color: #FFF;
    width: 100%;
    border-style: solid;
    border-width: thin;
    border-color: #F64E60;
    box-shadow: 0 0 3px #F64E60;
    padding: 3px 2px;
} */

.error-message {
    color: #F64E60;
    font-size: 13px;

}

.input-container {
    /* background-color: antiquewhite; */
    padding-top: 10px;
}

.input:focus {
    outline: none !important;
    border-style: solid;
    border-color: var(--default-color);
    box-shadow: 0 0 3px var(--default-color);
    /* height: 50px; */
}

.box {
    width: 100%;
    background-color: #FFF;
    border-radius: 30px;
    display: flex;
    align-items: center;
    padding: 10px;
}


.box>i {
    font-size: 20px;
    color: #777;
}

.box>input {
    flex: 1;
    height: 40px;
    border: none;
    outline: none;
    font-size: 18px;
    padding-left: 10px;
}

/* Size Textbox */
.input--small {
    height: 10px;
    font-size: 12px;
}

.input--medium {
    height: 30px;
    font-size: var(--fs-default);
    /* font-weight: var(--fw-default); */
}

.input--large {
    height: 40px;
    font-size: var(--fs-default);
}

.box--small {
    height: 10px;
    font-size: 12px;
}

.box--medium {
    height: 25px;
    font-size: 14px;
}

.box--large {
    height: 40px;
    font-size: 16px;
}

.inputWithIcon input[type=text] {
    padding-left: 30px;
}

.inputWithIcon {
    position: relative;
    /* z-index: -1; */
}

.inputWithIconMdi {
    position: absolute;
    left: 5px;
    top: 3px;
    /* padding: 3px 8px; */
    color: #aaa;
    transition: .3s;
    /* background-color: blue; */
}

.inputWithIcon input[type=text]:focus+i {
    color: var(--default-color);
}

.invalid-feedback {
    font-size: var(--fs-feedback);
}

.input-currency {
    width: 100%;
    border-radius: 10px;
    border-style: solid;
    border-width: thin;
    border-color: var(--border-color);
    padding: 3.9px 10px;
    font-size: var(--fs-default);
}

.input-currency:focus-visible {
    outline: none;
}

.input-currency:focus {
    border-color: var(--default-color);
}


.input-currency-error {
    width: 100%;
    border-radius: 10px;
    border-style: solid;
    border-width: thin;
    border-color: #dc3545;
    padding: 3.9px 10px;
    font-size: var(--fs-default);
}

.input-currency-error:focus-visible {
    outline: none;
}

.input-currency-error:focus {
    border-color: var(--default-color);
}