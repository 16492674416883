/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .list-staff .filter_container_totalrow {
        display: none;
    }

    .list-staff .upper-divider {
        display: none;
    }

    .list-staff .filter_container_search {
        padding: 0;
    }

    .list-staff .form-container-filter-button .btn {
        display: flex;
        padding: 8px 15px;
    }

    .list-staff .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
    }

    .list-staff .form-container-filter-button .btn div {
        display: none;
    }

    #filter-staff-form .form-container-filter {
        flex-direction: column;
    }

    .dropdown-container.list-staff .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    .list-staff .table-list thead {
        display: none;
    }

    .list-staff .table-list,
    .list-staff .table-list tbody,
    .list-staff .table-list tr,
    .list-staff .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-staff .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-staff .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-staff .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-staff .table-list td {
        /* text-align: right; */
        padding-left: 50%;
        position: relative;
        /* font-size: 12px; */
        /* padding-bottom: 20px; */
    }

    .list-staff .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        /* font-size: 12px; */
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-staff .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        /* background-color: rebeccapurple; */
    }

    /* Border radius for last column in table */
    .list-staff .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        /* background-color: brown; */
    }

    .add-staff.row {
        display: flex;
        flex-direction: column;
    }

    .add-staff.row .col-6 {
        width: 100%;
    }
}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .list-staff .filter_container_totalrow {
        display: none;
    }

    .list-staff .upper-divider {
        display: none;
    }

    .list-staff .filter_container_search {
        padding: 0;
    }

    .list-staff .form-container-filter-button .btn {
        display: flex;
        padding: 8px 15px;
    }

    .list-staff .filter_container_typelist .form-container-filter-button .btn svg {
        height: 1rem;
        width: 1rem;
        margin: 0;
    }

    .list-staff .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
    }

    .list-staff .form-container-filter-button .btn div {
        display: none;
    }

    #filter-staff-form .form-container-filter {
        flex-direction: column;
    }

    #filter-staff-form .form-label {
        font-size: 12px;
    }

    .container-div.list-staff .btn-link.no-underline {
        font-size: 12px;
    }

    .dropdown-container.list-staff .dropdown-item svg {
        height: 1rem;
        width: 1rem;
    }

    .dropdown-container.list-staff .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    .list-staff .table-list thead {
        display: none;
    }

    .list-staff .table-list,
    .list-staff .table-list tbody,
    .list-staff .table-list tr,
    .list-staff .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-staff .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-staff .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-staff .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-staff .table-list td {
        /* text-align: right; */
        padding-left: 50%;
        position: relative;
        font-size: 12px;
        /* padding-bottom: 20px; */
    }

    .list-staff .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-staff .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        /* background-color: rebeccapurple; */
    }

    /* Border radius for last column in table */
    .list-staff .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        /* background-color: brown; */
    }

    .add-staff.row {
        display: flex;
        flex-direction: column;
    }

    .add-staff.row .col-6 {
        width: 100%;
    }
}

.dropdown-container .dropdown-item svg {
    height: 1.2rem;
    width: 1.2rem;
}

.list-staff .form-container-filter-button .btn {
    display: flex;
}

.list-staff .form-container-filter-button .btn svg {
    height: 1.2rem;
    width: 1.2rem;
    margin: 0;
}

.img-box {
    width: 90px;
    margin-right: 30px;
}

.img-box>* {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 10px;
}

.row-upload-pic {
    align-items: center;
}

.stats-indicator {
    display: flex;
    font-size: 14px;
    margin-left: 30px;
}

.stats-indicator > * {
    margin: 0 5px;
}