/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {
    .top-section.reg {
        padding: 10px 30px;
    }
}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .top-section.reg {
        padding: 10px 20px;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {

    #basic-form .form-label {
        font-size: 12px;
    }

    #job-form .form-label {
        font-size: 12px;
    }

    #pw-form .form-label {
        font-size: 12px;
    }

    .top-section.reg {
        padding: 10px 15px;
    }

    .new-pw-note {
        font-size: 12px;
    }

    .checklist-icon {
        width: 10px;
    }

    /* .drNucU > span {
        padding-top: 0 !important;
    } */

    .consent-check .form-check-label {
        font-size: 12px;
    }

    .consent-check .form-check-input[type=checkbox] {
        height: 8px;
        width: 8px;
    }
}

.drNucU>span {
    padding-top: 0 !important;
}

.name-row {
    display: flex;
    padding-right: 8px;
}

.name-row>* {
    margin-right: 8px;
}

.wizard-section {
    /* background-color: blue; */
    display: flex;
    align-items: center;
    margin: auto;
}

.wizard-section>* {
    margin: 40px 0px;
    /* background-color: aqua; */
}

.square-num {
    background-color: #BDBDBD;
    border-radius: 10px;
    color: #EC782F;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.active-square {
    color: #EC782F;
    background-color: #FFF;
}

.wizard-right {
    display: flex;
    flex-direction: column;
}

.wizard-right>* {
    margin: 0px 5px;
}

.wizard-info {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: black;
}

.wizard-subinfo {
    font-weight: 300;
    font-size: 14px;
    line-height: 17px;
    color: #FFF;
}

.right-form-div {
    /* background: brown; */
    flex: 2;
    border-radius: 0px 10px 10px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    margin: auto;
    height: 100%;
}

.top-section {
    /* background-color: pink; */
    /* margin: auto 60px; */
    padding: 10px 60px;
    /* height: 100%; */
    overflow-y: auto;
}

/* Verify Page */
.verify-container {
    background: #FFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    padding: 25px 40px;
    border-radius: 10px;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.verify-title {
    font-size: 20px;
    font-weight: 700;
    padding: 10px 20px 10px 20px;
    /* background: orange; */
}

.verify-text {
    font-size: 14px;
    padding: 5px 20px 20px 20px;
    text-align: center;
    /* background: blue; */
}

/* Policy Page */
.policy-box {
    margin: auto;
    width: 60vw;
    height: 100vh;
    border: 1px solid #54595F;
    padding: 30px;
    background: #FFF
}

.policy-title {
    font-weight: 700;
    font-size: 30px;
    margin-bottom: 10px;
}

.policy-text {
    font-size: 14px;
    text-align: justify;
    word-break: break-word;
}