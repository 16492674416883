@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.cdnfonts.com/css/calibri-light');
@import './constants/theme.css';

@import 'bootstrap/dist/css/bootstrap.min.css';

/*XSMall*/
@media screen and (max-device-width: 576px) {
  form .form-label {
    font-size: 12px;
  }

  .btn svg {
    height: 1rem;
    width: 1rem;
  }

  .scrollable .nav {
    flex-wrap: unset !important;
  }

  .scrollable .nav-tabs {
    overflow-y: hidden;
    overflow-x: scroll;
  }

  .scrollable .nav-fill .nav-item .nav-link,
  .scrollable .nav-justified .nav-item .nav-link {
    width: 180px;
  }

  .nav-item .nav-link {
    font-size: 12px;
  }

  .btn-link.reminder-btn.set-rem {
    padding: 0;
  }

  .main-div {
    width: 100vw;
  }

  .info-noti-card .content-noti-card {
    font-size: 12px;
    text-align: left;
  }

  .read-btn.btn.btn-link {
    font-size: 12px;
    text-align: left;
  }
}

.main-overlay {
  background: rgba(0, 0, 0, 0.25);
  z-index: 1;
  position: fixed;
  width: calc(100vw - 62px);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-overlay .spinner-border {
  --bs-spinner-width: 4rem;
  --bs-spinner-height: 4rem;
}

.text-muted {
  font-size: 12px;
}

.read-num {
  border: none;
  padding: 0;
  font-weight: 600;
}

textarea.read-num.form-control[readOnly],
textarea.read-num.form-control[readOnly]:focus,
textarea.read-num.form-control[readOnly]:focus-visible {
  border: none;
  padding: 0;
  box-shadow: none;
  background: none;
}

svg {
  height: 1.2rem;
  width: 1.2rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar-ul, .sidebar-ul li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.jodit-wysiwyg ul, .jodit-wysiwyg li {
  padding: 0;
  margin: 0;
  margin-left: 10px;
}

/* affected in jspdf */
ul, ol {
  padding: 0;
  margin: 0;
  margin-left: 5px;
}

/* li {
  list-style: none;
} */

html {
  height: 100%;
}

body {
  font-family: var(--font-style);
  min-height: 100vh;
  /* background:  var(--menu-background); */
}

/* width */
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--box-shadow);
  border-radius: 10px;
  display: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--side-color);
  border-radius: 10px;
  /* display: none; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--side-color);
  display: block;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1052px;
  border-radius: 10px;
  border-style: solid;
}

.content-div {
  /* padding: 20px 30px; */
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.content-div>* {
  padding: 20px 30px;
}

.main-div {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  /* width: 80vw; */
  /* background-color: red; */
}

/* Navigation Side Menu */
.side-menu {
  /* position: fixed;
  z-index: 1; */
  /* width: 260px; */
  background: var(--side-background);
  /* background: red; */
  height: 100%;
  box-sizing: border-box;
  padding: 20px 10px;
  /* transition: width .2s ease-in; */
  box-shadow: 0px 4px 5px 1px rgba(0, 0, 0, 0.25);
  border-radius: 0px 20px 20px 0px;
}

.row-div {
  /* padding: 15px 0px 15px 0px; */
  display: flex;
  justify-content: space-between;
  /* background-color: red; */
  /* margin-bottom: 20px; */
}

.container-div {
  /* background-color: blue; */
  /* border-bottom: 5px solid black; */
  /* padding: 0px 0px 20px 0px; */
  /* margin-bottom: 20px; */
}

.top-title-div {
  /* background-color: red; */
  margin-bottom: 20px;
}

.both-side {
  /* background-color: blue; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.right-div {
  display: flex;
  /* flex: 1; */
  justify-content: flex-end;
  /* background-color: blue; */
  align-items: center;
  /* padding: 10px; */
}

.right-div.two-btn>* {
  margin-left: 20px;
}

.left-div {
  display: flex;
  /* flex: 1; */
  justify-content: flex-start;
  align-items: center;
  /* background-color: blueviolet; */
}

.left-flex9-div {
  display: flex;
  flex-direction: column;
  flex: 1;
  /* justify-content: flex-start; */
  /* background-color: greenyellow; */
  margin-left: 25px;
}

.center-div {
  display: flex;
  flex: 1;
  justify-content: center;
}

.logo-div {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px 0;
}

.center-flex1-div {
  display: flex;
  position: relative;
  height: 100%;
  /* flex: 1; */
  /* justify-content: center; */
  /* background-color: aqua; */
}

.center-flex1-div .textUpload {
  position: absolute;
  bottom: 0;
  /* top: 72%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  z-index: 10;
  opacity: 0;
  transition: all 0.5s ease;
  background-color: rgba(236, 120, 47, 0.9);
  width: 100%;
  text-align: center;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0;
  color: #FFF;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

.center-flex1-div:hover .textUpload {
  opacity: 1;
}

.center-flex1-div img {
  /* border: 0.3px solid var(--text-color); */
  border-radius: 5px;
  object-fit: contain;
  width: 100%;
  max-width: 140px;
}

.container-bg-col-div {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0px 100px;
  background-color: var(--white);
}

.container-between-div {
  display: flex;
  /* background-color: palegoldenrod; */
}

.container-form-div {
  /* display: flex; */
  /* flex: 1; */
  /* padding: 10px 0px; */
  /* background-color: antiquewhite; */
  /* border-style: solid; */
  /* display: flex */
  /* flex-direction: column; */
  /* padding: 10px; */
}

.container-form-row-div {
  display: flex;
  background-color: aqua;
}

.container-form-row1-div {
  padding-left: 0px;
  flex: 1;
  /* background-color: blueviolet; */

  /* padding-right: 30px; */
  /* padding-right: 25px; */
}

.container-form-row2-div {
  padding-left: 30px;
  flex: 1;
  /* background-color: aqua; */
  /* padding-right: 30px; */
  /* padding-right: 25px; */
}

.title-div {
  /* background-color: brown; */
  font-size: var(--fs-large);
  font-weight: var(--fw-default);
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /* padding-left: 10px; */
  /* justify-content: space-between; */
}

.title-div svg {
  /* background-color: red; */
  margin-left: 10px;
  height: 1.8rem;
  color: var(--blue-color);
}

.title-div i {
  color: var(--primary);
  padding-left: 10px;
  font-size: 25px;
}

.title-div-1 {
  /* flex: 7; */
  /* padding: 0px 9px 9px 9px; */
  font-size: var(--fs-large);
  font-weight: var(--fw-default);
  display: flex;
  align-items: center;
  /* background-color: blue; */
}

.title-div-2 {
  flex: 2;
  padding: 0px 9px 9px 9px;
}

/* .title-div > *:nth-child(2){
  flex: 1 1 20%;
  background-color: #467FD0;
} */

.container-info {
  display: flex;
  /* padding: 0px 5px; */
  /* background-color: violet; */
  padding: 10px 0;
}

.container-info-column {
  display: flex;
  flex-direction: column;
  /* flex: 1; */
  /* background-color: bisque; */
  /* border-right: 1px solid; */
  /* width: 300px; */
}

.column-right-div {
  /* background-color: blue; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.filter_container {
  display: flex;
  justify-content: space-between;
  /* background-color: aquamarine; */
  align-items: center;
}

.filter-container-dropdown {
  display: flex;
  align-items: center;
}

.filter_container_totalrow {
  /* flex: 1; */
  align-items: center;
  justify-content: center;
  display: inline-flex;
  padding: 0px 20px;
  /* background-color: blue; */
}

.upper-divider {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  border-left: 3px solid var(--main-color);
  height: 35px;
  margin: auto 0;
}

.filter_container_sort {
  justify-content: flex-start;
  display: flex;
}

.filter_container_search {
  flex: 3;
  align-items: center;
  /* justify-content: flex-start; */
  display: flex;
  padding: 0px 10px;
  /* background-color: purple; */
}

.filter_container_search-1 {
  /* flex: 1; */
  /* background-color: aqua; */
}

.filter_container_btnfilter {
  flex: 1;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  /* padding: 10px; */

}

.filter_container_typelist {
  /* flex: 1; */
  /* justify-content: flex-end; */
  /* display: flex; */
  /* padding: 10px 0; */
  /* background-color: red; */
}

.logo-section {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}



.main-menu .sub-menu {
  color: var(--text-default);
  box-sizing: border-box;
  /* padding-left: 30px; */
  max-height: 0;
  overflow: hidden;
  /* transition: .2s ease-in; */
  flex-direction: column;
  /* background-color: red; */
}

.main-menu .sub-menu.active {
  max-height: 100%;
  background-color: var(--sub-side-menu);
  /* background-color: red; */
}

.sub-menu.active {
  /* max-height: 200px; */
  background-color: var(--sub-side-menu);
  /* background-color: red; */
  margin: 0;
  padding: 0;
}

.sub-menu.active li,
.sub-menu.active ul {
  list-style-type: none;
}

.main-menu .sub-menu a {
  display: block;
  /* margin: 5px 0; */
  font-size: var(--fs-side-menu);
  font-weight: var(--fw-default);
  text-decoration: none;
  color: var(--text-default);
  box-sizing: border-box;
  padding: 10px 0px 10px 50px;
}

.main-menu .sub-menu a.active {
  /* change in background, not recorded in video */
  background: var(--sub-side-menu-active);
  display: block;
  /* border-radius: 5px; */
  color: var(--text-default);

}

.side-menu.inactive {
  opacity: 1;
  width: 0;
  height: 0;
  overflow: hidden;
}

.container {
  margin-left: 0px;
  transition: margin-left .2s ease-in;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

.container.inactive {
  margin-left: 80px;
}

/* Start Header */
.header {
  /* overflow: hidden; */
  background-color: var(--side-background);
  border-bottom-style: solid;
  border-bottom-color: var(--default-color);
  display: flex;
  /* flex-direction: row; */
  padding: 10px 30px;
  position: sticky;
  top: 0;
  z-index: 888;
}

.hamburger-menu {
  display: none;
}

.title-page {
  flex: 2;
  /* background-color: aquamarine; */
  /* padding-top: 30px; */
  display: flex;
  align-items: center;
}

.title-page-icon {
  padding: 10px;
  padding-left: 0;
  color: var(--default-color);
}

.title-page-icon svg {
  height: 1.5rem;
  width: 1.5rem;
}

.title-page-text {
  font-size: 20px;
  color: var(--text-color);
  font-weight: var(--fw-title);
}


.profile-icon {
  display: inline-flex;
  /* flex: 1; */
  /* flex-direction: row; */
  justify-content: right;
  align-items: center;
  /* background-color: red; */
  /* padding: 0px 20px; */
}

.username-div {
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: aqua; */
}

.icon-box {
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  display: flex;
  /* background-color: aqua; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.btn .icon-box svg {
  color: #EC782F;
  margin-right: 0;
}

.icon-box:hover {
  background-color: #EC782F;
}

.icon-box:hover * {
  color: #FFF;
}

.username-div>* {
  padding: 0 20px;
  padding-right: 0;
}

.username-div img {
  border-radius: 10px;
  /* border: 2px solid var(--default-color); */
  height: 40px;
  width: 40px;
  object-fit: cover;
}

.acct-box {
  /* background-color: orange; */
  display: flex;
  padding: 5px;
}

.acct-box:hover {
  /* background-color: #E5E5E5; */
  border-radius: 10px;
  background-color: var(--default-color);
  box-shadow: 0px 8px 16px 0px var(--box-shadow);
  color: #FFF;
}

.acct-box:hover * {
  color: #FFF;
}

.username-box {
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 0px 20px;
  font-size: var(--fs-default);
  font-weight: var(--fw-acc-name);
  color: var(--main-text);
  /* background-color: blue; */
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-item.dd-noti-item {
  padding: 0;
  white-space: normal;
}

.dropdown-item.dd-noti-item>* {
  margin: 0;
}

.dropdown-item.dd-noti-item.active,
.dropdown-item.dd-noti-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #EC782F;
}

.dropdown-content-header {
  font-size: var(--fs-header);
  padding: 15px;
  border-bottom: 3px solid #EC782F;
  background-color: #FFF;
  position: sticky;
  top: 0;
  font-weight: var(--fw-title);
  color: #212529;
}

.dd-menu {
  border-radius: 5px;
  /* height: 500px; */
  max-height: 500px;
  width: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdown-content-info {
  font-size: 16px;
  padding: 15px;
  border-bottom-style: solid;
  border-bottom-color: var(--sub-side-menu-active);
  border-bottom-width: thin;
  display: flex;
  /* flex-direction: column; */
  /* background-color: royalblue; */
  width: 100%;
}

.dropdown-content-info-date {
  display: flex;
  color: var(--main-color);
  /* padding-bottom: 10px; */
  /* background-color: grey; */
  /* align-items: center; */
}

.dropdown-content-info-date i {
  /* background-color: yellow; */
  height: 24px;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.dropdown-text {
  /* background-color: red; */
  padding: 0px 10px;
}

.noti-title {
  /* background-color: blue; */
  font-weight: var(--fw-default);
  font-size: var(--fs-header);
  color: var(--sub-color);
  /* line-height: 28px; */
}

.noti-date {
  font-size: var(--fs-notification-date);
  font-weight: 300;
  /* line-height: 15px; */
  /* margin-bottom: 10px; */
  /* background-color: greenyellow; */
  color: #54595F;
  ;
}

.dropdown-content-info-text {
  width: 400px;
  font-size: 14px;
  color: #54595F;
  /* background-color: burlywood; */
  line-height: 20px;
}

.dropdown-content a {
  color: var(--text-default);
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* .dropdown a:hover {background-color: var(--box-hover);} */

/* .all-noti-row {
  background-color: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  bottom: 0;
  border-top-style: solid;
  border-top-color: var(--sub-side-menu-active);
  border-top-width: 1px;
} */

.dd-all-noti {
  justify-content: center;
  padding: 0;
  margin: auto;
  background-color: #FFF;
  /* width: 30%; */
  position: sticky;
  /* top: 0; */
  bottom: 0;
  right: 0;
  left: 0;
}

.dd-all-noti .btn {
  padding: 0;
  margin: 0;
}

.dd-all-noti:hover {
  background: #FFF;
}

.no-new-noti {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 400px;
  font-size: 14px;
  font-weight: 300;
  color: rgba(236, 120, 47, 1);
}

.no-new-noti>* {
  margin: 5px 0;
}

.counter {
  border-radius: 50%;
  background-color: var(--noti-color);
  margin-bottom: 30px;
  color: var(--icon-bgcolor);
  width: 10px;
  height: 10px;
  animation: blink 2.2s linear infinite;
  position: absolute;
  right: 0;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

/* End Header*/

.all-noti-card {
  /* background-color: red; */
  display: flex;
  margin: 15px 20px;
}

.left-noti-card {
  /* background-color: blue; */
  display: flex;
  flex: 1;
}

.icon-noti-card {
  /* background-color: green; */
  display: flex;
  justify-content: center;
}

.box-icon {
  height: 40px;
  width: 40px;
  border: 3px solid #FFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #FFF;
  background-color: #EC782F;
}

.info-noti-card {
  /* background-color: pink; */
  display: flex;
  /* flex: 1; */
  flex-direction: column;
  /* margin: 0px 25px 0px 15px; */
  /* padding-right: 150px; */
}

.content-noti-card {
  /* background-color: yellow; */
  font-weight: 400;
  font-size: 14px;
  /* line-height: 20px; */
  color: #54595F;
  text-align: justify;
}

.main_container {
  display: flex;
  /* justify-content: space-between; */
  min-height: 100vh;

}

.container_toggle {
  display: flex;
  flex-direction: column;
  word-break: break-all;
}

.bloc-tabs_toggle {
  display: flex;
  background-color: var(--sub-side-menu);
  border-radius: 10px;
}

.content-tabs_toggle {
  flex-grow: 1;
  background-color: transparent;
}

.content_toggle {
  height: 100%;
  display: none;
}

.active-content_toggle {
  display: block;
  background-color: transparent;

}

.content-detail_toggle {
  background-color: transparent;
  border-radius: 15px;
}

.checkbox-container {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-container-default {
  display: flex;
  /* background-color: aqua; */
  align-items: center;
  /* flex-wrap: wrap; */
}

.form-container-default-1 {
  /* padding-right: 10px; */
  /* padding-left: 10px; */
  /* background-color: red; */
  display: flex;
}

.form-container-default-2 {
  display: flex;
  align-items: left;
  justify-content: left;
  /* background-color: blue; */
}

.form-container-default-1 img {
  border: 0.3px solid var(--text-color);
  border-radius: 5px;
  height: 40px;
  width: 40px;
  object-fit: contain;
}

.total_client_text {
  color: var(--default-color);
  font-size: var(--fs-large);
  font-weight: var(--fw-default);
  flex: 1;
  /* background-color: red; */
}

.client_text {
  font-size: var(--fs-default);
  font-weight: var(--fw-default);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  color: var(--main-color)
}

.collapses {
  display: none;
}

.collapses.in {
  display: block;
  background-color: var(--white);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 var(--box-shadow);
}

.form-container-filter {
  /* background-color: red; */
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}

.form-container-filter-1 {
  /* background-color: aqua; */
  flex: 1;
  padding-right: 10px;
}

.form-container-filter-2 {
  /* background-color: aqua; */
  flex: 1;

}

.form-container-filter-button {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  /* padding: 20px 0px 20px 30px; */
  /* background-color: aqua; */
}

.form-container-filter-button>* {
  margin-left: 10px;
}

.add-edu-container {
  display: none;
}

.add-edu-container.in {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: var(--white);
}


.accordion-button {
  background-color: white;
  /* z-index: 3; */
}

.accordion-button.collapsed {
  background-color: var(--sub-side-menu);
  font-weight: var(--fw-default);
}


.accordion-button:not(.collapsed) {
  color: var(--text-color);
  font-weight: var(--fw-default);
  background-color: var(--white);
}

.form-control:focus {
  border-color: var(--default-color);
  box-shadow: 0 0 0 0.01rem var(--default-color);
}

.form-select:focus {
  border-color: var(--default-color);
  box-shadow: 0 0 0 0.01rem var(--default-color);
}

.form-check-input:checked {
  /* background-color: var(--primary); */
  /* border-color: var(--primary); */
}

.form-check-label {
  font-size: var(--fs-default);
}

.col {
  font-size: var(--fs-default);
  /* font-weight: var(--fw-default);
  padding: 7px 12px; */
  /* text-align: center; */
}

.text-col {
  font-weight: var(--fw-default);
  padding: 7px 0px;
  text-transform: capitalize;
}

.column-card {
  flex: 1;
  /* padding: 10px 0px; */
  /* background-color: purple; */
}

.col-form-label {
  font-size: var(--fs-default);
  /* font-weight: var(--fw-default); */
}

.dropdown-toggle::after {
  content: none;
}

.actions-container {
  display: flex;
}

.actions-container>* {
  margin: 5px 10px;
}

.form-check-input:checked[type=checkbox] {
  background-color: var(--primary);
}

.swal2-title {
  font-size: 1.5rem !important;
  font-family: var(--font-style);
  /* border-style: solid; */
}

.swal2-html-container {
  font-size: 1.0rem !important;
}

.row-btn-center {
  /* background-color: red; */
  display: flex;
  justify-content: center;
}

.row-btn-center>* {
  margin: 10px;
}

.validation-star {
  color: var(--danger);
}

.right-div.reminder {
  margin-bottom: 10px;
}

.right-div.reminder svg {
  margin-right: 5px;
}

.big-text {
  font-size: 18px;
  color: #212529;
  line-height: 30px;
  font-weight: 600;
}

.orange-text {
  color: #EC782F;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding: 10px 0px;
}

.text {
  font-size: 14px;
  font-weight: 600;
  color: #212529;
}

.sub-text {
  font-size: 14px;
  font-weight: 500;
  color: #212529;
}

.mini-text {
  font-size: 14px;
  font-weight: 400;
  color: #212529;
  margin-bottom: 10px;
  text-align: justify;
  word-wrap: break-word;
  line-height: 20px;
}

.mini-text.no-mb {
  margin-bottom: 0;
  text-align: left;
}

.left-btn {
  /* background-color: pink; */
  margin-bottom: 20px;
}

.notes-row {
  /* background-color: green; */
  display: flex;
  padding-top: 10px;
}

.notes-row img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px 2px;
}

.notes-left-side {
  /* background-color: red; */
  display: flex;
  flex: 2;
  font-size: 14px;
  font-weight: 500;
  color: #54595F;
}

.notes-left-side>* {
  margin: 10px;
}

.notes-right-side {
  /* background-color: blue; */
  display: flex;
  font-size: 14px;
  font-weight: 500;
  color: #54595F;
}

.notes-right-side>* {
  margin: 10px 20px;
}

.radio-btn-row {
  /* background-color: red; */
  display: flex;
  align-items: center;
}

.radio-btn-row>* {
  margin: 0 20px;
}

.merge-cb>* {
  margin: 10px;
}

.nav-wizards {
  margin-bottom: 15px;
  text-align: center;
}

.dot-wizards {
  color: black;
  cursor: pointer;
  font-size: 36px;
  line-height: 1;
  margin: 0 15px;
  opacity: .4;
  text-shadow: none;
  transition: opacity 1s ease, text-shadow 1s ease;
  will-change: opacity, text-shadow;
}

.active-wizards {
  color: #EC782F;
  opacity: 1;
  text-shadow: 0 0px 8px;
}

.top-card {
  display: flex;
  /* justify-content: space-between; */
  margin: 0 10px;
  /* background-color: rebeccapurple; */
  align-items: center;
}

.top-left-card>* {
  margin-right: 15px;
}

.top-left-card {
  display: flex;
  align-items: center;
  flex: 1;
  /* background-color: blue; */
}

.top-right-card>* {
  margin-left: 15px;
}

.top-right-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  /* background-color: red; */
}

.interview-section {
  /* background-color: green; */
  display: flex;
  justify-content: space-between;
}

.iv-cdt hr:not([size]) {
  height: 2px;
  color: #EC782F;
}

.iv-cdt:last-child hr:not([size]) {
  display: none;
}

.interview-column {
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 5px;
  margin-left: 0;
}

.inv-dt {
  /* background-color: red; */
  display: flex;
}

.interview-column .text>*,
.interview-column .sub-text>* {
  margin-right: 10px;
}

.inv-dt>* {
  margin-right: 10px;
  margin-left: 2px;
}

.inv-dt svg {
  color: #EC782F;
}

.interview-btn-row {
  /* background-color: rebeccapurple; */
  display: flex;
  justify-content: flex-end;

}

.interview-button>* {
  margin: 5px 10px;
  margin-right: 0;
}

.exp-row {
  /* display: flex; */
  /* background-color: orange; */
  /* padding: 10px 0px; */
  /* margin-bottom: 20px; */
}

.exp-left {
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  flex: 1;
}

.exp-right {
  /* background-color: blue; */
  display: flex;
}

.exp-button {
  /* background-color: red; */
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.resume-section>* {
  margin: 5px 0;
}

.has-file-upload {
  /* background-color: red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cdt-row {
  /* background-color: pink; */
  display: flex;
  justify-content: space-between;
}

.cdt-column {
  /* background-color: rebeccapurple; */
  display: flex;
  flex-direction: column;
}

.left-section-cdt {
  /* background-color: blue; */
  display: flex;
  flex-direction: column;
  flex: 1;
}

.right-section-cdt {
  /* background: orange; */
  display: flex;
  flex-direction: column;
  flex: 1;
}

.more-section {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
}

.more-section-row {
  display: flex;
}

.exp-text {
  size: 14px;
}

.mini-text.flex-left {
  flex: 1 1 15%;
  /* background-color: gray; */
  margin-bottom: 0;
  line-height: 30px;
}

.sub-text.flex-right {
  flex: 1 1 60%;
  /* background-color: green; */
  margin-bottom: 0;
  word-break: break-all;
  text-align: justify;
  line-height: 30px;
}

.merge-table-thead {
  background: #C4C4C4;
  /* line-height: 20px; */
  font-size: 14px;
}

.form-check-merge {
  /* background: red; */
  text-align: justify;
  margin: auto 0;
}

/* View Candidate Profile */
.icon-box-lg {
  display: flex;
}

.icon-box-lg img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.candidate-status-div {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  color: #54595F;
  line-height: 20px;
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
}

.candidate-status {
  /* background-color: blue; */
  padding-bottom: 15px;
}

.status-reason {
  /* background-color: orange; */
  display: flex;
}

/*Accordion Bootstrap*/
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-item:first-of-type {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordion-item:last-of-type {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/*Form Bootstrap*/
.form-control {
  border-radius: 10px;
  font-size: 14px;
  color: #212529;
}

.form-select {
  border-radius: 10px;
  font-size: 14px;
  color: #212529;
}

.form-control[readOnly] {
  font-size: 14px;
  border-radius: 10px;
}

.form-label {
  font-size: 14px;
  font-weight: 600;
  color: #54595F;
  line-height: 20px;
}

.form-control::placeholder {
  color: #BDBDBD;
  font-size: 12px;
}

.form-control[type=file] {
  color: #BDBDBD;
  font-size: 12px;
}

/*Button Bootstrap*/
.btn-check:focus+.btn,
.btn:focus {
  box-shadow: none;
}

.btn:focus-visible {
  box-shadow: none;
  background-color: #FFF;
  color: #54595F;
}

.btn.btn-okay:focus-visible,
.btn.btn-okay:hover,
.btn.btn-okay:focus {
  background-color: #EC782F;
  color: #FFF;
}

.btn.btn-cancel:focus-visible,
.btn.btn-cancel:hover,
.btn.btn-cancel:focus {
  background-color: #E5E5E5;
  color: #212529;
}

.btn {
  border-radius: 10px;
  border: none;
  transition: transform 0.3s ease;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn svg {
  margin-right: 10px;
}

.btn-plus svg {
  margin-right: 0;
}

.btn-danger {
  background-color: #DF4759;
}

.btn-light svg {
  margin-right: 0;
}

.btn-link {
  color: #467FD0;
}

.btn-link.sign-in {
  color: #FFF;
  font-weight: 400;
}

.btn-link.delete-link {
  color: #DF4759;
  padding: 0;
  margin-left: 20px;
}

.btn-link.reminder-btn {
  padding: 10px 0;
}

.btn-link.read-btn {
  padding: 0;
}

.btn-link.no-underline {
  text-decoration: none;
  color: #54595F;
  padding: 0;
  display: flex;
  align-items: center;
  text-align: inherit;
}

.btn-link.modal-link {
  color: #EC782F;
  text-decoration: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.btn-info {
  background: #467FD0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #FFF;
  padding: 8px 24px;
}

.btn-info:hover {
  background: #467FD0;
  color: #FFF;
}

.btn-info:focus {
  background: #467FD0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-secondary.btn-register {
  background: #EC782F;
  border-radius: 10px;
  border: 2px solid #FFF;
  color: #FFF;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  padding: 10px 45px;
}

.btn-secondary.btn-register:hover {
  color: #212529;
  background-color: #FFF;
  border: 2px solid #EC782F;
}

.btn-secondary {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #54595F;
}

.btn-secondary:hover {
  background: #FFFFFF;
  color: #54595F;
}

.btn-primary.btn-plus {
  padding: 10px 15px;
}

.btn-primary {
  background: #EC782F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #FFF;
  /* font-weight: 500; */
}

.btn-primary:focus {
  background-color: #EC782F;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-primary:hover {
  background: #EC782F;
}

/*NavTab Bootstrap*/
.nav-container {
  /* background-color: red; */
  /* display: flex; */
}

.nav-container-nf {
  display: flex;
}

.nav-tabs {
  background-color: var(--sub-side-menu);
  border-radius: 10px;
  border: none;
}

.nav-item {
  display: flex;
  word-break: break-word;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--default-color);
  border: none;
}

.nav-tabs .nav-link {
  border: none;
  border-radius: 10px;
  padding: 15px 5px;
  /* background-color: orange; */
  width: 202px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-flex .nav-link {
  width: 200px;
}

.nav-flex-wide .nav-link {
  width: 250px;
}

.nav-link {
  border: none;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  padding: 0;
  color: black;
  font-weight: 600;
}

.nav-link:hover {
  color: black;
}

/*Dropdown Bootstrap*/
.dropdown-toggle.btn-primary {
  display: flex;
}

.dropdown-toggle.btn-link {
  padding: 0;
  text-decoration: none;
}

.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.dropdown-item.pp-settings {
  /* background-color: red; */
  padding: 10px;
}

.dropdown-item.pp-info:hover {
  background-color: #FFF;
  cursor: context-menu;
}

.dropdown-item>* {
  margin: 8px 10px;
}

.show>.btn-primary.dropdown-toggle {
  background-color: #EC782F;
  border: none;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.dropdown-menu {
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  padding: 0;
}

.dropdown-divider {
  margin: 0;
}

.dropdown-toggle.btn.btn-link:hover {
  transform: none;
}

/*Table Bootstrap*/
.outer-report-section {
  /* background: blue; */
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 10px;
}

.top-report-section {
  /* background: blue; */
  display: flex;
  justify-content: space-between;
}

.tbl-report {
  background-color: #E5E5E5;
}

.row-tbl-edit {
  display: flex;
  align-items: center;
}

.row-th-center {
  text-align: center;
  font-weight: 700;
}

th {
  font-weight: 600;
  font-size: 14px;
}

.table>:not(caption)>*>* {
  border-bottom: none;
}

.notes-table {
  font-size: 14px;
  font-weight: 500;
  color: #54595F;
}

.td-data-img {
  padding-bottom: 10px;
}

.td-data-img img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px 2px;
  box-shadow: 0 1px 2px 0 #212529;
  margin: 0;
}

.td-notes {
  text-align: left;
}

.notes-table td:nth-child(2) {
  /* background-color: blue; */
  width: 60%
}

.notes-table td:nth-child(3) {
  /* background-color: red; */
  width: 15%;
}


/*Card Bootstrap*/
.card-body.card-in-card {
  padding: 20px 0px;
}

.modal-content {
  border: none;
}

.modal-header {
  background: #EC782F;
  color: #FFF;
}

.modal-center {
  /* background-color: red; */
  display: flex;
  justify-content: center;
  /* flex-direction: column;
  padding: 10px 100px; */
}

.modal-center-column {
  flex-direction: column;
}

.modal-center-icon {
  display: flex;
  justify-content: center;
}

/*Custom SweetAlert2*/
.btn-okay {
  background-color: #EC782F;
  margin-left: 5px;
  color: #FFF;
}

.btn-cancel {
  background-color: #E5E5E5;
  margin-right: 5px;
}

.swal-title {
  font-weight: 600;
}

.swal-popup {
  border-radius: 10px;
}

.meeting-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.meeting-header>* {
  margin-right: 10px;
}

.meeting-info {
  /* background: green; */
  height: 360px;
  overflow-y: scroll;
}

.meeting-info>* {
  padding-bottom: 20px;
  padding-right: 10px;
}

.meeting-info>:last-child {
  padding-bottom: 0;
}

.meeting-btn-row {
  /* background: red; */
  display: flex;
  padding-top: 15px;
  justify-content: flex-end;
}

.meeting-btn {
  display: flex;
}

.meeting-btn>* {
  margin-left: 10px;
}

/* PDF Viewer CSS*/
.pdf-container {
  /* background: red;
  width: 90%;
  max-width: 1110px;
  margin-top: calc(566px / -2);
  margin-bottom: 4em;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  font-size: 19px; */
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
}

.modal-dialog {
  min-width: fit-content;
}

.page-controls {
  position: absolute;
  bottom: 5%;
  left: 50%;
  background: white;
  opacity: 1;
  transform: translate(-50%);
  transition: opacity ease-in-out .4s;
  box-shadow: 0 30px 40px 0 rgba(16, 36, 94, .2);
  border-radius: 4px;
}

.page-controls button:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-controls button:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.page-controls button {
  width: 44px;
  height: 44px;
  background: white;
  border: 0;
  font-size: 12px;
  border-radius: 4px;
}

.page-controls button:hover {
  background-color: #BDBDBD;
}

.page-controls span {
  font-size: 12px;
  padding: 0 10px;
}