/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {
    .db-summary .dashboard-card-title {
        font-size: 14px;
    }
    
    .title-page .title-page-icon {
        display: none;
    }

    .acct-box .username-box {
        display: none;
    }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .title-page .title-page-icon {
        display: none;
    }

    .title-page .title-page-text {
        font-size: 16px;
    }

    .acct-box .username-box {
        display: none;
    }

    .db-summary {
        /* flex-direction: column; */
        row-gap: 15px;
    }

    .db-summary .card.dashboard-card {
        width: 48%;
    }

    .db-summary .dashboard-card-title {
        font-size: 14px;
    }

    .db-summary .db-summary-right {
        display: none;
    }

    .db-stats-row {
        flex-direction: column;
        row-gap: 20px;
    }

    .db-stats-row .same-dim {
        width: 100%;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {
    .db-summary {
        /* flex-direction: column; */
        row-gap: 15px;
    }

    .db-summary .card.dashboard-card {
        width: 48%;
    }

    .db-summary .dashboard-card-title {
        /* font-size: 3.4vw; */
        font-size: 12px;
    }

    .db-summary .db-summary-right {
        display: none;
    }

    .db-stats-row {
        flex-direction: column;
        row-gap: 20px;
    }

    .db-stats-row .dashboard-card-heading {
        font-size: 18px;
    }

    .db-stats-row .same-dim {
        width: 100%;
        max-height: unset;
    }

    .todo-entry .btn.btn-primary {
        font-size: 12px;
        padding: 6px 16px;
    }

    .todo-list .nav-link {
        font-size: 12px;
    }

    .todo-list .nav-tabs .nav-link {
        padding: 0 5px 10px 5px;
    }

    .new-cdt-reg .table-list .header_text {
        font-size: 12px;
    }

    .new-cdt-reg .table-list td {
        padding: 5px 10px;
    }

    .top .table-list td {
        padding: 5px 10px;
    }

    .new-cdt-reg .table-list .body_text {
        font-size: 12px;
    }

    .candidate-row .todo-name {
        font-size: 12px;
    }

    .new-cdt-reg .table-list .body_text .candidate-row {
        font-size: 12px;
    }

    #content .header {
        padding: 5px 30px;
    }

    .title-page .title-page-icon {
        display: none;
    }

    .title-page .title-page-text {
        font-size: 16px;
    }

    .acct-box .username-box {
        display: none;
    }
}

.db-summary {
    /* background-color: pink; */
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.card.dashboard-card {
    /* background-color: red; */
    display: flex;
    flex-direction: row;
    padding: 15px 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 24%;
}

.dashboard-card:nth-child(1) {
    background-color: #FF934F;
}

.dashboard-card:nth-child(2) {
    background-color: #F7CB15;
}

.dashboard-card:nth-child(3) {
    background-color: #98D9C2;
}

.dashboard-card:nth-child(4) {
    background-color: #76BED0;
}

.db-summary-left {
    /* background-color: blue; */
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.db-summary-right {
    /* background-color: orange; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.db-summary-right svg {
    color: #FFF;
}

.dashboard-card-num {
    font-weight: 800;
    font-size: 30px;
    line-height: 30px;
    margin: 0;
    color: #FFF;
}

.dashboard-card-title {
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 30px;
    margin: 0;
    color: #FFF;
}

.todo-entry {
    /* background-color: pink; */
    display: flex;
    padding: 10px 0;
}

.todo-entry div:nth-child(1) {
    /* background-color: orange; */
    display: flex;
    flex: 1;
}

.todo-entry div:nth-child(2) {
    padding-left: 10px;
}

.todo-list {
    /* background-color: violet; */
    padding-top: 10px;
}

.todo-container {
    /* background-color: orange; */
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0;
    height: 250px;
    overflow-y: auto;
}

.todo-row {
    /* background-color: olive; */
    display: flex;
    padding: 10px 0;
}

.todo-cb {
    /* background-color: blue; */
    flex: 0 0 5%;
}

.todo-info {
    /* background-color: brown; */
    flex: 1 0 90%;
    text-align: justify;
    word-break: keep-all;
    padding-right: 40px;
}

.todo-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
}

.todo-date {
    font-size: 12px;
    font-weight: 400;
    color: #54595F;
}

.todo-date.other {
    font-size: 14px;
}

.todo-more {
    /* background-color: yellow; */
    flex: 0 0 auto;
    /* padding-left: 20px; */
}

.todo-more .btn {
    /* background: grey; */
    padding: 0;
    margin: 0;
    border-radius: 0;
    display: flex;
}

.todo-more .btn svg {
    /* background-color: red; */
    margin: 0;
}

.nav-tabs.dashboard-tab {
    border-radius: 0;
    background-color: #FFF;
    border-bottom: 1px solid #54595F;
}

.tab-content.dashboard-tab-content {
    margin: 0;
}

.nav-tabs.dashboard-tab .nav-link.active {
    border-bottom: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-color: #54595F;
    border-width: 1px;
}

.db-stats-row {
    /* background-color: red; */
    display: flex;
    margin-top: 20px;
    column-gap: 15px;
}

.div-todo {
    /* background-color: red; */
    flex: 1;
}

.div-cdt {
    /* background: blue; */
    flex: 1;
}

.div-cdt .dashboard-card-padding {
    height: 100%;
    overflow: scroll;
    /* background-color: orange; */
}

.card.dashboard-card-padding {
    height: 100%;
    padding: 15px 20px;
    /* background-color: red; */
}

.dashboard-card-heading {
    font-weight: 800;
    font-size: 20px;
    line-height: 30px;
    margin: 0;
    color: #54595F;
    /* background-color: pink; */
}

.new-cdt-reg {
    /* background-color: blue; */
    /* padding: 10px 0; */
    height: 400px;
    overflow-y: auto;
}

.new-cdt-reg thead tr th {
    background-color: #FFF;
    position: sticky;
    top: 0;
    /* padding: 0; */
}

.new-cdt-reg .trow {
    border-radius: 0;
}

.new-cdt-reg table {
    /* background-color: blue; */
    border-spacing: 0px 10px;
    border-collapse: collapse;
}

.no-cdt {
    height: 400px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #DF4759;
    font-weight: 500;
    font-size: 24px;

}

.candidate-row {
    display: flex;
    flex-direction: column;
    /* background-color: pink; */
    padding: 10px 0;
}

.same-dim {
    /* background-color: red; */
    width: 33%;
    max-height: 65vh;
}

.one {
    /* background-color: #80A1C1; */
    color: #F6511D;
}

.two {
    /* background-color: #4472CA; */
    color: #FFB400;
}

.tri {
    /* background-color: #5E7CE2; */
    color: #00A6ED;
}

.top {
    height: 420px;
    overflow-y: auto;
}

.top .trow {
    border-radius: 0;
}