/* @import '~bootstrap/dist/css/bootstrap.min.css'; */

:root {
    /* --bs-body-bg: #FDF2EA; */
    --font-style: 'Poppins', sans-serif;
    --default-color: #EC782F;
    --menu-background: #FDF2EA;
    --side-background: #FFFF;   
    --text-menu-select: #FFFF;
    --text-default: #212529;
    --sub-side-menu: #E5E5E5;
    --sub-side-menu-active: #BDBDBD;
    --side-color: #666;
    --icon-bgcolor: #fff;
    --text-color: #54595F;
    --box-hover: #DDD;
    --border-color: #BDBDBD;
    --noti-color: #DF4759;

    /* Container Color */
    --container-bg: #FFF;
    --box-shadow: rgba(0, 0, 0, 0.25);


    /* Font Color */
    --main-text: #54595F;

    /* Icon Color */
    --main-color: #54595F;
    --sub-color: #212529;
    --blue-color: #467FD0;

  

    /* Font Size */
    --fs-side-menu: 12px;
    --fs-notification-date: 12px;
    --fs-default: 14px;
    --fs-header: 16px;
    --fs-title: 24px;
    --fs-large: 20px;
    --fs-btn-default: 14px;
    --fs-tbl-header: 14px;
    --fs-tbl-body: 14px;
    --fs-feedback: 12px;
    
    /* Font weight */
    --fw-acc-name: 500;
    --fw-table-info: 500;
    --fw-default: 600;
    --fw-title: 700;
    --fw-notification: 600;
    --fw-btn-default: 500;


    /* Table Style */
    --table-body-font-size: 15px;
    --table-background-tbody: #FFF;
    --table-border-color: rgba(236,120,47,0.1);
    --table-box-shadow: rgba(0, 0, 0, 0.2);

    /* Card Info */
    --active: #42BA96;
    --progress: #467FD0;
    --discussion: #54595F;
    --hold: #DF4759;
    --completed: #42BA96;
    --infor: #5bc0de;

    /* Button Color */
    --primary: #467FD0;
    --warning: #ffd028;
    --danger: #DF4759;
    --success: #4CAF50;
    --white: #ffff;
    --dark: #181717;
    --default: #E5E5E5;
    

    /* Pagination Color */
    --pagination-bgcolor: #EC782F;
  
  }