@import '../../constants/theme.css';

/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
  .modal-content .modal-title {
    font-size: 18px;
    line-height: 20px;
  }

  .modal-content .modal-header .btn-close {
    width: unset;
    height: unset;
  }

  .modal-footer .btn {
    font-size: 12px;
    padding: 8px 15px;
  }
}

/* Bootstrap Modal CSS */

.modal-title {
  font-weight: var(--fw-default);
  font-size: var(--fs-title)
}

.modal-footer {
  border-top: none;
}

/* Custom Modal CSS */

.popup-box {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  padding: 0 20px;
  overflow: auto;
  /* width: 100%;
  height: 100%;
  overflow: auto;
  display: none;
  padding: 0 20px;
  z-index: 2; */
  /* position: fixed;
    background: #21252900050;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    padding: 30px 40px;
    transform: translate(-50%, -50%);
    z-index: 4; */
}

.sub-popup-box {
  background-color: #fff;
  border-radius: 10px;
  /* max-width: 988px; */
  margin: 50px auto;
  padding: 5px;
  /* display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 16px 0 var(--box-shadow);
    width: 50%;
    height: 100%;
    margin: 0 auto;
    margin-top: calc(100vh - 75vh - 20px);
    overflow-x: scroll; */
}

.popup-box-xlarge {
  max-width: 1500px;
}

.popup-box-large {
  max-width: 988px;
}

.popup-box-medium {
  max-width: 761px;
}

.popup-box-small {
  max-width: 500px;
}

.header-popup-box {
  flex: 1;
  position: sticky;
  top: 0;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: var(--border-color);
  padding: 15px;
  font-size: 24px;
  background-color: var(--white);
  z-index: 2;

}

.content-box {
  flex: 12;
  /* padding: 15px; */
  /* background-color: bisque; */
  /* overflow-x: scroll; */
}

.footer {
  flex: 1;
  position: sticky;
  left: 0;
  bottom: 0;
  /* width: 100%; */
  background-color: white;
  padding: 10px;
  color: black;
  /* border-style: solid; */
  z-index: 2;
  /* text-align: center; */
}

.main-popup-div {
  padding: 15px;
}