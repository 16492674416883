/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {

    .noti-reminder .writing-text,
    .noti-reminder .row-input .btn,
    .noti-reminder .row-input-column div,
    .noti-reminder .checkbox-list .form-check-label {
        font-size: 12px;
        line-height: unset;
    }

    .noti-reminder .row-input {
        align-items: flex-start;
    }

    .noti-reminder .row-input svg {
        height: 1rem;
        width: 1rem;
    }

    .noti-reminder .row-input .del-noti .btn div {
        display: none;
    }
}

.title-text {
    font-weight: 700;
    font-size: 20px;
    line-height: 36px;
    color: #54595F;
    margin-bottom: 10px;
}

.writing-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #54595F;
}

.row-input-column {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #54595F;
    /* background-color: red; */
}

.row-input-column .form-setting-control {
    width: 80px;
}

.row-input {
    /* background: green; */
    display: flex;
    align-items: center;
}

.row-input>* {
    margin: 10px 15px 10px 0px;
}

.row-input svg {
    height: 1.2rem;
    width: 1.2rem;
}

.form-check-merge.form-check .form-check-input:disabled~.form-check-label,
.form-check-merge.form-check .form-check-input[disabled]~.form-check-label {
    cursor: default;
    opacity: 1;
}