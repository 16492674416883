@import '../../constants/theme.css';

/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    #react-select-2-placeholder {
        font-size: 12px;
    }

    .list-cli .select--candidate {
        font-size: 12px;
        height: unset;
    }
}

.dd-div {
    /* background-color: aqua; */
    padding: 5px 0px;
}

.select {
    display: flex;
    /* min-height: px; */
    flex-wrap: wrap;
}

.select option{
    background-color: var(--white);
    color: var(--text-default);
}

.select--default--style:focus {
    outline: none !important;
    border-color: var(--default-color);
    box-shadow: 0 0 3px var(--default-color);
}

.select--default--style:hover {
    outline: none !important;
    border-color: var(--default-color);
    box-shadow: 0 0 3px var(--default-color);
}

.select--default--style{
    width: 100%;
    font-size: 16px;
    font-family: var(--font-style);
    border-style: solid;
    border-radius: 10px;
    border-color: var(--border-color);
}

.select--status--style{
    font-size: 10px;
    font-family: var(--font-style);
    /* background-color: blue; */
    /* color: var(--white); */
    border-style: none;
    border-radius: 10px;
    /* padding: 1px */
    /* border-color: var(--border-color); */
}

.primary-color{
    background-color: var(--primary);
    color: var(--white);
}

.error-color{
    background-color: var(--danger);
    color: var(--white);
}

/* Size Dropdown */
.select--small{
    height: 10px;
    font-size: 12px;
    /* background-color: aqua; */
}

.select--medium{
    height: 25px;
    font-size: 12px;
    /* background-color: aqua; */
    padding: 4px 10px;
}

.select--large{
    height: 40px;
    font-size: 16px;
    background-color: aqua;
}

.select--candidate {
    height: 37px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 4px 10px;
}

.filter {
    /* background-color: white; */
    background-color: red;
  }

.invalid-dropdown{
    /* display: none; */
    width: 100%;
    margin-top: 0.25rem;
    font-size: 12px;
    color: #dc3545;

}



  


