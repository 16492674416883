/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .clientJD .btn.btn-link {
        padding: 0;
    }

    .container-bg-col-div {
        padding: 0;
    }
}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .filter_container_search {
        padding: 0;
    }

    .clientJD .btn.btn-link {
        padding: 0;
    }

    .container-bg-col-div {
        padding: 0;
    }

    .container-info.edu-added .card-edu-btn {
        justify-content: flex-start;
    }
}