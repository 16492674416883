/* For Tablet View */
@media screen and (min-device-width: 640px) and (max-device-width: 1024px) {
    .top-section.forgot-pw, .reset-form-container {
        padding: 10px 30px !important;
    }
}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 425px) and (max-device-width: 640px) {
    .top-section.forgot-pw, .reset-form-container {
        padding: 10px 20px !important;
    }

    .top-section.forgot-pw .title-form-section {
        text-align: justify;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {
    .top-section.forgot-pw, .reset-form-container {
        padding: 10px 15px !important;
    }

    .top-section.forgot-pw .title-form-section {
        text-align: justify;
    }

    .row-link-button.sign-in .btn-link.no-underline {
        font-size: 12px;
    }

    .form-setting-label.forgot-sentence {
        font-size: 12px;
        line-height: unset;
    }

    #reset-password-form .form-label {
        font-size: 12px;
    }

    .reset-form-container .row-btn-bottom {
        margin-top: 0 !important;
    }

    .reset-form-container .row-btn-bottom > * {
        margin: 0 !important;
    }

    .reset-form-container .row-btn-bottom .btn {
        font-size: 12px;
        width: 100%;
        padding: 8px 0;
        margin-bottom: 15px !important;
    }
}

.form-setting-label.forgot-sentence {
    font-weight: 400;
}

.row-link-button.sign-in .btn-link.no-underline {
    text-decoration: underline;
}

.btn-register-left-side {
    margin-top: 20px;
}

.btn-outline-primary.btn-register {
    background: #FFF;
}

.new-pw-note {
    font-size: 14px;
    padding: 15px 0;
}

.fGlehb.invalid,
.ddzDop.valid {
    align-items: center;
}

.checklist-icon {
    width: 14px;
}

.reset-container {
    /* background: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.reset-form-container {
    /* background: green; */
    display: flex;
    flex-direction: column;
    padding: 60px;
    border-radius: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.2);
}