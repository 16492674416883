@import '../../constants/theme.css';

/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .list-cli .card_text--medium {
        padding: 5px 10px;
    }
}

.card_text {
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-content: center;
    text-transform: capitalize;
    font-weight: 500;
    letter-spacing: 0.01cm;
    /* padding: 5px; */
    /* margin-right: 10px; */
}

.card_text_container {
    padding-top: 5px;
    /* background-color: red; */
    display: flex;
    flex-wrap: wrap;
}


/* Start Card Colors and Styles */
/* solid */

.card_text--danger--solid {
    background-color: var(--danger);
    color: var(--white);
    border: none;
}

.card_text--primary--solid {
    background-color: var(--primary);
    color: var(--white);
    /* border: none; */
    padding: 5px 0px;
}

.card_text--discussion--solid {
    background-color: var(--discussion);
    color: var(--white);
    border: none;
}

.card_text--progress--solid {
    background-color: var(--progress);
    color: var(--white);
    border: none;
}

.card_text--hold--solid {
    background-color: var(--hold);
    color: var(--white);
    border: none;
}

.card_text--warning--solid {
    background-color: var(--warning);
    color: var(--dark);
    border: none;
}

.card_text--infor--solid {
    background-color: var(--infor);
    color: var(--dark);
    border: none;
}

.card_text--completed--solid {
    background-color: var(--completed);
    color: var(--white);
    border: none;
}

.card_text--default--solid {
    background-color: var(--default);
    color: var(--dark);
    border: none;
    /* padding: 5px; */
    /* box-shadow: 0 8px 16px 0 var(--box-shadow); */
}

.card_text--active--solid {
    background-color: var(--active);
    color: var(--white);
    border: none;
}

/* End Card Text Styles */

/* Start Card Text Size */

.card_text--xsmall {
    padding: 4px 15px;
    font-size: 12px;
    background-color: var(--default);
    margin-bottom: 5px;
    margin-right: 5px;
}

.card_text--small {
    padding: 5px 0;
    font-size: 12px;
    /* width: 120px; */
}

.card_text--medium {
    padding: 10px 20px;
    font-size: 12px;
    text-transform: capitalize;
}

.card_text--large {
    padding: 100px 100px;
    font-size: 1000px;
}

/* End Card Text Size */

.card-container-column {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 20px;
    background-color: var(--white);

}

.card_container-row {
    display: flex;
    flex-direction: row;
}

.card_container-row-left {
    display: flex;
    justify-content: center;
    flex: 1;
    /* background-color: aquamarine; */
    /* padding: 10px; */
}

.card_container-row-left img {
    border: 1px solid var(--text-color);
    border-radius: 5px;
}

.card_container-row-right {
    flex: 6;
}

.card {
    border-radius: 10px !important;
    border-style: none !important;
    /* width: 300px;
    display: flex;
    flex-direction: row;
    background-color: aqua;
    border-style: solid;
    padding: 10px;
    margin: 10px; */
}

.card-body {
    border-radius: 10px;
    /* background: red; */
    padding: 20px 30px;
}

/* .card:hover{

    box-shadow: 5px 10px 20px 1px rgba(0,0,0,0.253)!important;
} */

.card-title {
    font-size: 30px;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    /* padding: 10px; */
}

.grid-item {
    background-color: var(--side-background);
    /* border: 1px solid rgba(0, 0, 0, 0.8); */
    box-shadow: 0 8px 16px 0 var(--box-shadow);
    padding: 20px;
    font-size: 30px;
    text-align: center;
    margin: 20px;
    border-radius: 10px;
}

.card-container {
    display: flex;
    flex-direction: row;
    font-size: 16px;
}

.card-container-checkbox .card-container-info {
    flex: 1;
    display: flex;

}

.card-container-image {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.card-container-image p {
    color: var(--sub-side-menu-active);
}

.card-container-image img {
    border: 1px solid var(--text-color);
    border-radius: 5px;

}

.card-container-content {
    background-color: var(--menu-background);
    /* border-radius: 10px; */
    font-size: 13px;
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    padding: 10px;
}

.card-container-content-above {
    background-color: var(--menu-background);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 16px;
    border-bottom-style: solid;
    border-width: thin;
    border-bottom-color: var(--sub-side-menu-active);
    display: flex;
    flex-direction: row;

}

.card-container-content-above-1 {
    font-size: 13px;
    color: var(--sub-side-menu-active);
    padding-left: 20px;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin: 10px;
}

.card-container-content-above-2 {
    font-size: 13px;
    padding-left: 20px;
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin: 10px;
}

.card-container-content-above-2 span {
    padding-left: 10px;
}

.card-container-content-below {
    background-color: var(--menu-background);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    font-size: 13px;
    display: flex;
    flex-direction: row;

}

.card-container-content-below-1 {
    font-size: 13px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 10px 10px 10px 0px;
    width: 50%;

}

.card-container-content-below-2 {
    font-size: 13px;
    display: flex;
    margin: 10px 0px 10px 0px;
    width: 100%;

}

.card-education {
    display: flex;
    flex: 1;
    flex-direction: row;
    /* padding: 10px; */
    padding-bottom: 20px;
    /* border-bottom-style: solid;
    border-bottom-width: thin;
    border-bottom-color: var(--border-color); */
    /* width: 100%; */
    /* background-color: blueviolet; */


}

.card-education__row-flex5 {
    /* display: flex; */
    flex: 5;
    display: flex;
    flex-direction: column;
}

.card-education__row-flex5>*:nth-child(1) {
    font-size: var(--fs-default);
    font-weight: var(--fw-default);
}

.card-education__row-flex1 {
    /* display: flex; */
    flex: 1;
}

.card-experience {
    /* display: flex; */
    flex: 1;
    display: flex;
    flex-direction: column;
    /* background-color: blue; */
}

.card-experience-box {
    /* display: flex; */
    /* background-color: aqua; */
    flex: 1;
    display: flex;
    /* padding-bottom: 15px; */
}

.card-exp-title {
    /* background-color: bisque; */
    margin-bottom: 25px;
}

.card-exp-btn {
    /* background-color: pink; */
    display: flex;
}

.card-experience-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    /* background-color: brown; */
}

.card-experience-right {
    /* background-color: blue; */
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card-experience-form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 0 5px 0;
}

.card-experience-form-col-15 {
    flex: 1 1 20%;
    color: var(--text-color);
    font-size: var(--fs-default);
}

.card-experience-form-col-85 {
    flex: 1 1 80%;
    color: var(--text-default);
    font-size: var(--fs-default);
    font-weight: var(--fw-default);
    /* color: #212529; */
}

.card-font-style {
    font-size: var(--fs-header);
    font-weight: var(--fw-default);
}

.card-font-label {
    color: var(--text-color);
    font-size: var(--fs-default);
}

.card-text-style {
    font-size: var(--fs-default);
}

.card-referee {
    display: flex;
    margin-bottom: 10px;
    /* background-color: aqua; */
    font-size: 16px;
    font-weight: 600;
    justify-content: space-between;
}

.card-referee-button {
    padding: 0px 10px;
    display: flex;
    flex-direction: row;
    /* background-color: aqua; */
}

.card-edu-row {
    /* background-color: pink; */
    display: flex;
}

.card-edu-info {
    /* background-color: purple; */
    display: flex;
    flex-direction: column;
    flex: 3;
}

.card-edu-show-cert {
    /* background: red; */
    display: inline-flex;
    align-items: flex-start;
}

.card-edu-btn {
    /* background-color: bisque; */
    display: inline-flex;
    margin-left: 100px;
    align-items: flex-start;
    /* flex: 1; */
}

.card-ref-box {
    /* background-color: red; */
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin-bottom: 20px;
    margin-right: 20px;
}

.card-ref-box.col-4 {
    /* background: orange; */
    width: 31.5%;
}

.card-ref-row {
    /* background-color: brown; */
    display: flex;
    padding: 10px;
}

.card-misc-row {
    /* background-color: blue; */
    display: flex;
    margin-bottom: 30px;
}

.card-misc {
    /* background-color: pink; */
    display: flex;
    flex: 2 1 90%;
    font-weight: 400;
    font-size: 14px;
}

.card-misc-btn {
    /* background-color: brown; */
    display: flex;
    flex: 2 1 15%;
    align-items: flex-start;
    justify-content: flex-end;
}