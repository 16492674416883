.loader-container {
    height: calc(100vh - 85px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mini-loader-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .loader-container img {
    margin: 0;
    background: yellow;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%) } */