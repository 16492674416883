/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .list-cand #basic-form .col-6 {
        width: 100%;
    }

    .list-cand #basic-form .col-5 {
        width: 100%;
        margin-top: 10px;
    }

    .list-cand .text-muted {
        font-size: 10px;
    }

    .list-cand .form-check-label {
        font-size: 12px;
    }

    .inv-cand .meeting-header .sub-text, 
    .inv-cand .iv-cdt .text,
    .inv-cand .iv-cdt .sub-text,
    .inv-cand .interview-column .inv-datetime {
        font-size: 12px;
    }

    .inv-cand .interview-section {
        flex-direction: column;
    }
}

.inv-datetime {
    font-size: 14px;
}