.editors{
    border-style: solid;
    border-color: aqua;
}

.richtext-div{
    /* background-color: aquamarine; */
    border-style: solid;
    border-width: thin;
    border-radius: 10px;
    border-color: #BDBDBD;
    /* max-height: auto; */
}

.richtext-div:focus-within{
    border-color: var(--default-color);
    box-shadow: 0 0 3px var(--default-color);
}

.richtext-div-invalid{
    border-style: solid;
    border-width: thin;
    border-radius: 10px;
    border-color: #dc3545;
}

.richtext-div-valid{
    border-style: solid;
    border-width: thin;
    border-color: #198754;
}

.invalid-richtext{
    /* display: none; */
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}

.jodit-status-bar {
  display: none;
  visibility: hidden;
}

.jodit-toolbar__box:not(:empty) {
    border-radius: 10px 10px 0 0 !important;
}

/* .jodit-container:not(.jodit_inline) {
    border: none;
} */

/* .jodit-container:not(.jodit_inline) .jodit-workplace {
  overflow: auto !important;
  max-height: 250px !important;
} */

.jodit-toolbar-editor-collection.jodit-toolbar-editor-collection_mode_horizontal.jodit-toolbar-editor-collection_size_middle {
  /* background: red; */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.jodit-container:not(.jodit_inline) {
  /* border: 1px solid #dadada; */
  border: none;
  border-radius: 10px !important;
}

/* .ql-container.ql-snow{ border: none !important;}

.ql-picker.ql-font {
    .ql-picker-item {
      font-size: 0;
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }
  
  .ql-picker.ql-font{
    .ql-active {
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
      }
    }
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Arial"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Arial"]::before
  {
      font-family: "Arial", cursive;;
      content: "Arial" !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Calibri"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Calibri"]::before
  {
      font-family: "Calibri", cursive;;
      content: "Calibri" !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Sans Serif"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Sans Serif"]::before
  {
      font-family: "Sans Serif", cursive;;
      content: "Sans Serif" !important;
  }
  
  .ql-picker.ql-font .ql-picker-label[data-value="Serif"]::before, 
  .ql-picker.ql-font .ql-picker-item[data-value="Serif"]::before
  {
      font-family: "Serif", cursive;;
      content: "Serif" !important;
  }
  
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
    .ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before
    {
    content: 'Times New Roman';
    font-family: 'Times New Roman';
    }
  
  /* Set content font-families */
  /* .ql-font-Arial {
    font-family: "Arial";
  }
  .ql-font-Calibri {
    font-family: "Calibri";
  }
  .ql-font-Sans-Serif {
    font-family: "Sans Serif";
  }
  .ql-font-times-new-roman {
    font-family: 'Times New Roman';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="9px"]::before {
    content: "9";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
    content: "10";
    font-size: 12px !important;
  }
  
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="11px"]::before {
    content: "11";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
    content: "12";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
    content: "14";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
    content: "18";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
    content: "20";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="22px"]::before {
    content: "22";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
    content: "26";
    font-size: 12px !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="28px"]::before {
    content: "28";
    font-size: 12px !important;
  }

  .ql-toolbar.ql-snow {
    border-radius: 10px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.ql-editor {
  min-height: 150px;
  max-height: 300px;
} */