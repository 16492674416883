/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    #create-iv-form .row .mb-3.col-4 {
        width: 100%;
    }
}