/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .page-mgt .container-div .btn-link.no-underline {
        font-size: 12px;
    }

    .page-mgt .table-list thead {
        display: none;
    }

    .page-mgt .table-list,
    .page-mgt .table-list tbody,
    .page-mgt .table-list tr,
    .page-mgt .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .page-mgt .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .page-mgt .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .page-mgt .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .page-mgt .table-list td {
        padding-left: 50%;
        position: relative;
        font-size: 12px;
    }

    .page-mgt .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .page-mgt .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .page-mgt .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }

    /* View Page Management */
    .view-pagemgt .btn {
        padding: 8px 15px;
        font-size: 12px;
    }

    .view-pagemgt .btn svg {
        height: 1rem;
        width: 1rem;
    }
    
    #update-page-form .form-label {
        font-size: 12px;
    }

    #update-page-form .col-2.form-label {
        width: 100%;
    }
}

#update-page-form .row {
    --bs-gutter-x: 0;
}