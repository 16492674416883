
/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .col-15.form--text--gray {
        font-size: 12px;
    }
}

.form-container{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    /* width: 300px; */
    padding: 0 0 15px 0;
    /* background-color: aqua; */
    /* border-bottom: 1px solid black; */
}

.bottom-info-div {
    /* background-color: aqua; */
    display: flex;
    line-height: 20px;
    padding: 10px 0px;
}

.col-15{
    /* flex: 1 1 14%; */
    min-width: 150px;
    font-weight: var(--fw-default);
    font-size: var(--fs-default);
    align-content: center;
    line-height: 20px;
}

.col-30{
    /* flex: 1 1 29%; */
    min-width: 150px;
    font-weight: var(--fw-title);
    font-size: var(--fs-default);
    align-content: center;
    line-height: 20px;
}

.col-70{
    /* flex: 1 1 71%; */
    font-size: var(--fs-default);
    color: #212529;
}

.col-85{
    /* flex: 1 1 86%; */
    color: #212529;
}

.form-info{
    display: flex;
    flex-wrap: wrap;
    /* padding: 10px; */
    /* background-color: coral; */
}

.form-info > *:nth-child(1){
    flex: 1 1 20%;
    min-width: 150px;
    height: 25px;
    align-content: center;
    
}

.form-info > *:nth-child(2){
    flex: 1 1 80%;
}

.form-input{
    display: flex;
    flex-direction: row;
}

.form-info-input{
    display: grid;
    grid-template-columns: auto auto auto;
    /* background-color: aqua; */
    /* width: 180px; */
	/* float: left; */
    
}

.form--text--gray{
    color: var(--text-color);
    font-weight: 600;
    margin-top: 0;
}

.form--text--black{
    color: var(--text-default);
}

