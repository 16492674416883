@import '../../constants/theme.css';

.tab-pane-div {
  /* background-color: red; */
  padding: 20px 30px;
}

.tab-header-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: var(--default-color);
  padding: 20px 30px;
}

.tab-header-staf-div{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom-style: solid;
  border-bottom-width: thin;
  border-bottom-color: var(--default-color);
  padding: 5px 10px;
}

.tab-header-font{
  font-size: 20px;
  font-weight: 600;
  color: var(--default-color);
  text-transform: capitalize;
}

.tab-content>.active {
  border-radius: 10px;
}

.info-container {
  padding: 20px;
}

.row-btn-bottom {
  /* background-color: red; */
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.row-btn-bottom>* {
  margin: 5px 0px 5px 10px;
}

.dropdown-container {
  /* background: red; */
  display: flex;
  justify-content: center;
}

/*Fazmin's*/

.custom-tab {
  padding: 15px;
}

.tab-content{
  background-color: var(--side-background);
  margin-top: 20px;
  border-radius: 10px;
}

.container-tabs {
  display: flex;
  flex-direction: column;
  /* position: relative; */
  /* background: #f1f1f1; */
  word-break: break-all;
  /* background-color: red; */
}

.bloc-tabs {
  display: flex;
  background-color: var(--sub-side-menu);
  border-radius: 10px;
  /* background-color: blueviolet; */
}

.tabs {
  padding: 15px 10px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  box-sizing: content-box;
  /* position: none; */
  /* outline: none; */
  font-size: 14px;
  font-weight: var(--fw-default);
}

.active-tabs  {
  background: var(--side-background);
  border-radius: 10px;
  color: var(--default-color);
}

.active-tabs::before {
  content: "";
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
}

button {
  border: none;  
}

.content-tabs {
  flex-grow : 1;
  /* background-color: transparent; */
  /* background-color: aqua; */
}

.content {
  padding: 15px 0 0;
  height: 100%;
  display: none;
}

.content h2 {
  padding: 0px 0 5px 0px;
}

.content hr {
  width: 100px;
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.content p {
  width: 100%;
  height: 100%;  
}

.active-content {
  display: block;
  background-color: transparent; 
}

/*Chart CSS*/
.chart-wrapper {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  /* justify-content: space-between; */
  /* background-color: red; */
}

.chart-wrapper>:nth-child(1) {
  padding-bottom: 30px;
} 

.chart-box {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: flex;
  justify-content: space-between;
  column-gap: 30px;
  /* flex-direction: column; */
  /* background-color: blue; */
}

.chart-container {
  /* background-color: orange; */
  display: flex;
  flex-direction: column;
}

.highcharts-data-table table {
  border-collapse: collapse;
  border-spacing: 0;
  background: white;
  min-width: 100%;
  margin-top: 20px;
  font-size: 0.9em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  border: 1px solid #BDBDBD;
  padding: 0.5em;
}

.highcharts-data-table tr:nth-child(even),
.highcharts-data-table thead tr {
  background: #f8f8f8;
}

.highcharts-data-table tr {
  cursor: pointer;
}

.highcharts-data-table tr:hover {
  background: #eff;
}

.highcharts-data-table caption {
  border-bottom: none;
  font-size: 1.1em;
  font-weight: bold;
  display: none;
}

.highcharts-sort-ascending::after {
  content: " ↓";
}

.highcharts-sort-descending::after {
  content: " ↑";
}

.highcharts-container {
  width: 100% !important;
  height: 100% !important;
}

.highcharts-root {
  width: 100% !important;
  height: 100% !important;
}

.chart-box>div {
  width: 100% !important;
  height: 100% !important;
}