/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .list-cli .filter_container_totalrow {
        display: none;
    }

    .list-cli .upper-divider {
        display: none;
    }

    .list-cli .table-list thead {
        display: none;
    }

    .list-cli .table-list,
    .list-cli .table-list tbody,
    .list-cli .table-list tr,
    .list-cli .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-cli .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-cli .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-cli .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-cli .table-list td {
        /* text-align: right; */
        padding-left: 50%;
        position: relative;
        /* font-size: 12px; */
        /* padding-bottom: 20px; */
    }

    .list-cli .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        /* font-size: 12px; */
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-cli .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        /* background-color: rebeccapurple; */
    }

    /* Border radius for last column in table */
    .list-cli .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        /* background-color: brown; */
    }

    .cli-page .filter_container_search {
        padding: 0;
    }

    .cli-page .form-container-filter-button .btn {
        display: flex;
    }

    .cli-page .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
        background-color: #FFF;
    }

    .cli-page .form-container-filter-button .btn:focus-visible svg {
        color: #54595F
    }

    .cli-page .form-container-filter-button .btn:hover {
        transform: unset;
    }

    #filter-active .form-container-filter,
    #filter-potential .form-container-filter {
        flex-direction: column;
    }

    .dropdown-container .dropdown {
        display: contents;
    }

    .dropdown-container.list-cli .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    /* View Active Clients */

    .list-cli .row-div {
        margin-bottom: 15px;
    }

    .list-cli .right-div {
        /* margin-bottom: 15px; */
    }

    /* View Assignment */
    .top-card .top-left-card>* {
        margin-right: 5px;
    }

    .top-card .big-text {
        font-size: 14px;
    }

    .top-card .mini-text,
    .top-card .text {
        text-align: start;
    }

    /* Tab Subsidiary */
    .right-div.comp-selected {
        font-size: 12px;
    }

    /* Create Staff Edu Info */
    .create-st .container-bg-col-div {
        padding: 0;
    }

    /* Create Staff Ref Info */
    #add-ref .add-reference {
        flex-direction: column;
    }

    #add-ref .add-reference .col-6,
    #add-ref .add-reference .col-8 {
        width: 100%;
    }

    .cdt-row {
        justify-content: flex-start;
    }
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {

    .list-cli .filter_container_totalrow {
        display: none;
    }

    .list-cli .upper-divider {
        display: none;
    }


    .list-cli .table-list thead {
        display: none;
    }

    .list-cli .table-list,
    .list-cli .table-list tbody,
    .list-cli .table-list tr,
    .list-cli .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-cli .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-cli .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-cli .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-cli .table-list td {
        /* text-align: right; */
        padding-left: 50%;
        position: relative;
        font-size: 12px;
        /* padding-bottom: 20px; */
    }

    .list-cli .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-cli .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        /* background-color: rebeccapurple; */
    }

    /* Border radius for last column in table */
    .list-cli .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        /* background-color: brown; */
    }

    .cli-page .filter_container_search {
        padding: 0;
    }

    .cli-page .form-container-filter-button .btn {
        display: flex;
        padding: 8px 15px;
    }

    .cli-page .filter_container_typelist .form-container-filter-button .btn svg {
        height: 1rem;
        width: 1rem;
        margin: 0;
    }

    .cli-page .form-container-filter-button .btn:focus-visible {
        box-shadow: none;
        background-color: #FFF;
    }

    .cli-page .form-container-filter-button .btn:focus-visible svg {
        color: #54595F
    }

    .cli-page .form-container-filter-button .btn:hover {
        transform: unset;
    }

    .cli-page .form-container-filter-button .btn div {
        display: none;
    }

    #filter-active .form-container-filter,
    #filter-potential .form-container-filter {
        flex-direction: column;
    }

    #filter-active .form-label,
    #filter-potential .form-label {
        font-size: 12px;
    }

    .list-cli .table-list .checkbox-container {
        justify-content: flex-start;
    }

    .container-div.list-cli .btn-link.no-underline {
        font-size: 12px;
    }

    .dropdown-container .dropdown {
        display: contents;
    }

    .dropdown-container .dropdown-item {
        font-size: 12px;
    }

    .dropdown-container.list-cli .dropdown-item svg {
        height: 1rem;
        width: 1rem;
    }

    .dropdown-container.list-cli .dropdown-toggle.btn.btn-light {
        padding: 3px 10px;
        width: 100%;
        display: block;
    }

    .form-container-default-2 svg {
        height: 1rem;
        width: 1rem;
    }

    /* View Active Clients */
    .container-between-div .center-flex1-div {
        display: none;
    }

    .left-flex9-div .right-div .btn div {
        display: none;
    }

    .left-flex9-div .act-cli-info {
        flex-direction: column;
    }

    .left-flex9-div .col-4,
    .left-flex9-div .col-3 {
        width: 100%;
    }

    .container-info-column .col-70,
    .container-form-div .col-70,
    .container-form-div .col-30 {
        font-size: 12px;
    }

    .tab-pane-div .right-div .btn {
        font-size: 12px;
    }

    .tab-pane-div .right-div .btn svg {
        margin-right: 5px;
    }

    .right-div .btn div {
        display: none;
    }

    .row-div .right-div .btn svg {
        margin-right: 5px;
    }

    .row-div .right-div .btn-primary svg {
        margin-right: 0;
    }

    .list-cli .left-div {
        flex-direction: column;
        align-items: flex-start;
    }

    .list-cli .tab-staff {
        align-items: flex-start;
        margin-top: 10px;
    }

    .left-div .stats-indicator {
        margin-left: 0;
        margin-top: 10px;
        font-size: 12px;
        align-items: center;
    }

    .container-form-div .client-dets {
        flex-direction: column;
    }

    .client-dets.row .col-6 {
        width: 100%;
    }

    #infor-form .row .mb-3.col-6 {
        width: 100%;
    }

    #infor-form .form-check-label {
        font-size: 12px;
    }

    .list-cli .row-div {
        margin-bottom: 15px;
    }

    /* Tab Meeting */
    .btn.set-rem {
        font-size: 12px;
    }

    /* Tab Proposal */
    .col-3.form-label {
        width: 100%;
    }

    /* Tab Assignment */
    #assign-form .add-assg {
        flex-direction: column;
    }

    #assign-form .add-assg .mb-3 .col-3.form-label {
        width: 100%;
    }

    #assign-form .create-date.col-sm-3 {
        margin-bottom: 10px;
    }

    /* Tab Subsidiary */
    .right-div.comp-selected {
        font-size: 12px;
    }

    /* Create Meeting */
    .create-meet .btn {
        padding: 8px 15px;
        font-size: 12px;
    }

    .create-meet .breadcrumb-div {
        display: none;
    }

    #create-meeting-form .create-meet {
        flex-direction: column;
    }

    #create-meeting-form .create-meet .col-6 {
        width: 100%;
    }

    /* Create Staff Basic Info */
    .create-st .btn {
        padding: 8px 15px;
        font-size: 12px;
    }

    .create-st .breadcrumb-div {
        display: none;
    }

    #basic-form .create-st-form {
        flex-direction: column;
    }

    #basic-form .create-st-form .col-6 {
        width: 100%;
    }

    /* Create Staff Edu Info */
    .create-st .container-bg-col-div {
        padding: 0;
    }

    .edu-duration.form-label {
        color: #FFF;
    }

    .edu-added .card-edu-row {
        flex-direction: column;
    }

    .edu-added .card-edu-info {
        font-size: 12px;
    }

    .edu-added .card-edu-show-cert {
        padding: 10px 0;
    }

    .edu-added .card-edu-btn {
        margin: 0;
        justify-content: flex-end;
    }

    /* Create Staff Exp Info */
    .exp-duration {
        display: none;
    }

    .current-cb .form-check-label {
        font-size: 12px;
    }

    .create-st .cdt-row {
        flex-direction: column;
    }

    .create-st .cdt-row .col-5 {
        width: 100%;
    }

    .create-st .cdt-row .col-6 {
        width: 100%;
    }

    .create-st .cdt-row .col-11 {
        /* width: unset; */
    }

    .create-st .cdt-row .sub-text,
    .create-st .cdt-row .mini-text {
        font-size: 12px;
    }

    /* Create Staff Ref Info */
    #add-ref .add-reference {
        flex-direction: column;
    }

    #add-ref .add-reference .col-6,
    #add-ref .add-reference .col-8 {
        width: 100%;
    }

    .card-ref-box.col-4 .card-text-style {
        font-size: 12px;
    }

    .create-st .ref-card.card {
        display: flex;
        flex-direction: column;
    }

    .card-ref-box.col-4 {
        width: 100%;
    }

    /* View Potential Clients */
    .container-div.both-side .btn {
        padding: 8px 15px;
        font-size: 12px;
    }

    .container-div.both-side .breadcrumb-div {
        display: none;
    }

    /* Create Proposal */
    .create-props .btn {
        padding: 8px 15px;
        font-size: 12px;
    }

    .create-props .breadcrumb-div {
        display: none;
    }

    #proposal-form .create-props {
        flex-direction: column;
    }

    #proposal-form .create-props .col-6 {
        width: 100%;
    }

    /* Convert Client */
    #convert-form .convert-cli {
        flex-direction: column;
    }

    #convert-form .col-6 {
        width: 100%;
    }

    .radio-btn-row .form-check-label {
        font-size: 12px;
    }

    .col .radio-btn-row>* {
        margin-left: 5px;
    }

    .card-in-card .both-side {
        flex-direction: column;
        align-items: flex-start;
    }

    .card-in-card .btn div {
        font-size: 12px;
    }

    /* View Assignment */
    .list-cli .top-card {
        flex-direction: column;
    }

    .top-card .top-left-card {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
    }

    .top-card .top-left-card>* {
        margin-right: 5px;
    }

    .top-card .big-text {
        font-size: 14px;
    }

    .top-card .mini-text,
    .top-card .text {
        font-size: 12px;
        text-align: start;
    }

    .top-card .top-right-card {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        row-gap: 10px;
    }

    .top-card .top-right-card>* {
        margin-right: 5px;
        margin-left: 0;
    }

    /* Tab Candidates */
    .right-div.two-btn .btn {
        padding: 8px 15px;
    }

    .row-div .right-div.two-btn>* {
        margin-left: 10px;
    }

    .row-div .right-div .btn {
        font-size: 12px;
    }

    .row-div .right-div svg {
        margin-right: 0;
    }

    svg.generate {
        display: none;
    }

    .down-arrow {
        margin: 0 !important;
    }

    /* Tab Statistics*/
    .chart-wrapper .chart-box {
        flex-direction: column;
        row-gap: 30px;
    }

    .chart-wrapper>:nth-child(1) {
        padding-bottom: 0;
    }

    /* Tab Files Documents */
    .title-text.report {
        font-size: 16px;
    }

    /* Create Progress Report */
    .outer-report-section .report-date,
    .outer-report-section .report-name,
    #progress-form .report-label {
        font-size: 12px;
    }

    .main-div.ecp-report {
        width: 200vw;
    }
}

.right-div.comp-selected {
    font-size: 14px;
}

.cand-exp-loop {
    margin-bottom: 40px;
}

.checkbox-list .list-title {
    font-size: 14px;
    font-weight: 600;
}

.report-name {
    font-size: 20px;
}

.ref-card.card {
    display: inline;
}

.form-container-default-2 svg {
    height: 1.2rem;
    width: 1.2rem;
}

.dropdown-container .dropdown-item svg {
    height: 1.2rem;
    width: 1.2rem;
}

.cli-page .form-container-filter-button .btn {
    display: flex;
}

.container-div.both-side .btn {
    display: flex;
}

.row-div .btn {
    display: flex;
}

.cli-page .form-container-filter-button .btn svg {
    height: 1.2rem;
    width: 1.2rem;
}