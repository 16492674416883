/* For Tablet View */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/* For Mobile Portrait View */
@media screen and (max-device-width: 480px) and (orientation: portrait) {}

/* For Mobile Landscape View */
@media screen and (max-device-width: 640px) and (orientation: landscape) {}

/* For Mobile Phones Portrait or Landscape View */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .meeting .split-container {
        flex-direction: column;
        row-gap: 15px;
        height: unset;
    }

    .meeting .left-split {
        /* height: 40vh; */
        margin: 0;
        flex: unset;
    }

    .meeting .right-split {
        /* height: 40vh; */
        margin: 0;
        flex: unset;
    }

    .meeting .meeting-overflow {
        height: 40vh;
        overflow-y: auto;
    }

    .meeting .tab-content-meeting {
        height: 40vh;
        overflow-y: auto;
    }

    .meeting .meeting-empty svg {
        height: 5rem;
        width: 5rem;
    }    
}

/* For iPhone 4 Portrait or Landscape View */
@media screen and (max-device-width: 576px) {
    .meeting .split-container {
        flex-direction: column;
        row-gap: 15px;
        height: unset;
    }

    .meeting .left-split {
        /* height: 40vh; */
        margin: 0;
        flex: unset;
    }

    .meeting .meeting-title,
    .meeting .meeting-date,
    .meeting .meeting-desc,
    .meeting .meeting-person,
    .meeting .meeting-location,
    .meeting-main-cont .meeting-sub-info .modal-link,
    .meeting-sub-info .meeting-purpose,
    .meeting .box-empty-container {
        font-size: 12px;
        line-height: unset;
    }

    .meeting .right-split {
        /* height: 40vh; */
        margin: 0;
        flex: unset;
    }

    .meeting .tab-content-meeting {
        height: 40vh;
        overflow-y: auto;
    }

    .meeting .meeting-icon {
        flex-direction: column;
        font-size: 12px;
        padding: 5px 0;
    }

    .meeting .meeting-icon svg, .meeting .meeting-location svg {
        height: 1rem;
        width: 1rem;
    }

    .meeting .right-split .meeting-title {
        font-size: 18px;
    }

    .meeting .interview-column.meeting .meeting-status {
        font-size: 13px;
    }
    
    .meeting .meeting-overflow {
        height: 40vh;
    }

    .meeting-main-cont .meeting-subheader {
        font-size: 14px;
    }

    .interview-button .btn {
        font-size: 12px;
        padding: 8px 15px;
    }

    .meeting .meeting-empty svg {
        height: 4rem;
        width: 4rem;
    }

    .meeting .meeting-empty-wording {
        font-size: 12px;
    }
}

.dashboard-tab.meeting {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.split-container {
    display: flex;
    height: 84vh;
}

.left-split {
    background-color: #FFF;
    flex: 1;
    margin-right: 10px;
    border-radius: 10px;
}

.right-split {
    background-color: #FFF;
    flex: 2;
    margin-left: 10px;
    border-radius: 10px;
    padding: 20px;
}

.tab-content-meeting {
    margin-top: 5px;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    /* background-color: red; */
    height: 75vh;
    overflow-y: auto;
}

.box-click-meeting {
    border-bottom: 4px solid #E5E5E5;
    padding: 10px 20px;
    cursor: pointer;
}

.box-click-meeting:hover {
    background-color: rgba(236, 120, 47, 0.3);
}

.box-click-container.active {
    background: rgba(236, 120, 47, 0.3);
}

.box-click-container.active:hover {
    background: none;
}

.box-click-container:last-child .box-click-meeting {
    border-bottom: none;
}

.meeting-title,
.meeting-date,
.meeting-desc,
.meeting-person,
.meeting-purpose {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.right-split .meeting-title {
    font-size: 24px;
    font-weight: 800;
}

.meeting-icon {
    /* background-color: red; */
    display: flex;
    padding: 10px 0px;
    line-height: 24px;
    font-size: 14px;
}

.meeting-icon>* {
    margin-right: 20px;
}

.meeting-icon svg {
    margin-right: 10px;
    height: 1.2rem;
    width: 1.2rem;
}

.meeting-location {
    display: flex;
    font-size: 14px;
    line-height: 24px;
}

.meeting-location svg {
    margin-right: 10px;
    height: 1.2rem;
    width: 1.2rem;
}

.interview-column.meeting .interview-button>* {
    margin-left: 0;
    margin-right: 10px;
    margin-top: 20px;
}

.interview-column.meeting .meeting-status {
    font-size: 15px;
    font-weight: 600;
}

.meeting-overflow {
    /* background-color: red; */
    height: 52vh;
    overflow-y: auto;
}

.meeting-main-cont {
    padding-bottom: 20px;
}

.meeting-subheader {
    font-weight: 600;
}

.meeting-sub-info {
    font-size: 14px;
    font-weight: 500;
}

.meeting-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.meeting-empty svg {
    height: 6rem;
    width: 6rem;
}

.meeting-empty-wording {
    font-size: 18px;
    font-weight: 400;
    padding: 20px;
    color: #E5E5E5;
    text-align: center;
}

.box-empty-container {
    padding: 20px;
    font-size: 14px;
    text-align: center;
}

.bg-blink {
    animation: blink 2.2s linear infinite;
}

@keyframes blink {
    0% {
        opacity: 0;
    }

    50% {
        opacity: .5;
    }

    100% {
        opacity: 1;
    }
}