/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .list-useracc .filter_container_totalrow {
        display: none;
    }

    .list-useracc .upper-divider {
        display: none;
    }

    .list-useracc .filter_container_search {
        padding: 0;
    }

    .list-useracc .table-list thead {
        display: none;
    }

    .list-useracc .table-list,
    .list-useracc .table-list tbody,
    .list-useracc .table-list tr,
    .list-useracc .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-useracc .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-useracc .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-useracc .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-useracc .table-list td {
        padding-left: 50%;
        position: relative;
    }

    .list-useracc .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-useracc .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .list-useracc .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }

    .list-usercat .table-list thead {
        display: none;
    }

    .list-usercat .table-list,
    .list-usercat .table-list tbody,
    .list-usercat .table-list tr,
    .list-usercat .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-usercat .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-usercat .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-usercat .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-usercat .table-list td {
        padding-left: 50%;
        position: relative;
    }

    .list-usercat .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-usercat .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .list-usercat .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .list-useracc .filter_container_totalrow {
        display: none;
    }

    .list-useracc .upper-divider {
        display: none;
    }

    .list-useracc .filter_container_search {
        padding: 0;
    }

    .container-div.list-useracc .btn-link.no-underline {
        font-size: 12px;
    }

    .list-useracc .table-list thead {
        display: none;
    }

    .list-useracc .table-list,
    .list-useracc .table-list tbody,
    .list-useracc .table-list tr,
    .list-useracc .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-useracc .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-useracc .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-useracc .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-useracc .table-list td {
        padding-left: 50%;
        position: relative;
        font-size: 12px;
    }

    .list-useracc .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-useracc .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .list-useracc .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }

    /* View User Access */
    .view-useracc .btn {
        padding: 8px 15px;
        font-size: 12px;
    }

    .view-useracc .btn svg {
        height: 1rem;
        width: 1rem;
    }

    .permissions-row {
        flex-direction: column;
    }

    .permissions-row .permissions-content {
        width: 100%;
    }

    .checkbox-list .list-title {
        font-size: 12px;
        line-height: unset;
    }

    .list-usercat .table-list thead {
        display: none;
    }

    .list-usercat .table-list,
    .list-usercat .table-list tbody,
    .list-usercat .table-list tr,
    .list-usercat .table-list td {
        display: block;
        width: 100%;
        background: unset;
    }

    .list-usercat .table-list tr {
        background-color: #FFF;
        margin-top: 15px;
    }

    .list-usercat .table-list tr:nth-child(1) {
        margin-top: 0px;
    }

    .list-usercat .table-list td:nth-child(even) {
        background-color: #E5E5E5;
    }

    .list-usercat .table-list td {
        padding-left: 50%;
        position: relative;
        font-size: 12px;
    }

    .list-usercat .table-list td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-size: 12px;
        text-align: left;
        font-weight: 600;
    }

    /* Border radius for first column in table */
    .list-usercat .table-list td:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }

    /* Border radius for last column in table */
    .list-usercat .table-list td:last-child {
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
    }
}

.bg-white-container {
    padding: 30px;
    background-color: var(--container-bg);
    box-shadow: 0px 4px 20px var(--box-shadow);
    border-radius: 10px;
}

.permissions-row {
    /* background-color: blue; */
    display: flex;
}

.permissions-content {
    /* background-color: aqua; */
    width: 33.33%;
}

.checkbox-title-container {
    /* background-color: red; */
    display: flex;
    align-items: center;
}

.checkbox-title-container>* {
    margin: 10px;
    margin-left: 0px;
}

.checkbox-title {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
}

.checkbox-list {
    /* background-color: violet; */
    display: flex;
    align-items: center;
}

.checkbox-list>* {
    margin: 8px 10px 2px 0px;
}

.list-title {
    /* background-color: pink; */
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}