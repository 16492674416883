@import '../../constants/theme.css';


/*Large */
@media screen and (min-device-width: 993px) and (max-device-width: 1200px) {}

/*Small, Medium */
@media screen and (min-device-width: 577px) and (max-device-width: 992px) {
    .list-cli .btn-number-each-text {
        background-color: #818181;
    }

    .list-cli .btn-number-each-text button div {
        color: #818181;
    }

    .list-cli .btn-number-each .btn-add-inv {
        background-color: #FFF;
    }

    .row-btn-bottom .btn {
        width: 100%;
        justify-content: center;
        margin-left: 0;
    }

    .row-btn-bottom .btn.btn-secondary {
        margin-right: 5px;
    }

    .row-btn-bottom .btn.btn-primary[type=submit] {
        margin-left: 5px;
    }
}

/*XSMall*/
@media screen and (max-device-width: 576px) {
    .list-cli .btn-number-each-text {
        background-color: #818181;
        height: 2rem;
        width: 1rem;
    }

    .list-cli .btn-number-each-text button div {
        color: #818181;
    }

    .list-cli .btn-number-each .btn-add-inv {
        background-color: #FFF;
    }

    .list-cli .btn-number-each button {
        font-weight: 400;
    }

    .row-btn-center .btn {
        font-size: 12px;
        width: 100%;
        padding: 8px 0;
        justify-content: center;
        /* margin-left: 0; */
    }

    .row-btn-bottom .btn {
        font-size: 12px;
        width: 100%;
        padding: 8px 0;
        justify-content: center;
        margin-left: 0;
    }

    .row-btn-bottom .btn.btn-secondary {
        margin-right: 5px;
    }

    .row-btn-bottom .btn.btn-primary[type=submit] {
        margin-left: 5px;
    }
}

.btn-container {
    /* padding: 0px 5px; */
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 200px; */
    /* padding: 10px; */
    /* margin-left: 10px; */
}

.buttonC {
    font-family: var(--font-style);
    font-weight: 400;
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.buttonC:hover {
    transform: translateY(-3px);
}

.buttonC:active {
    background-color: #c0c0c0;
    box-shadow: 0 3px #666;
    transform: translateY(4px);
}

/* Button Colors and Styles */
/* solid */
.btn--primary--solid {
    background-color: var(--primary);
    color: var(--white);
    border: none;
    box-shadow: 0 4px 4px 0 var(--box-shadow);
}

.btn--warning--solid {
    background-color: var(--warning);
    color: var(--white);
    border: none;
}

.btn--danger--solid {
    background-color: var(--danger);
    color: var(--white);
    border: none;
    box-shadow: 0 4px 4px 0 var(--box-shadow);
}

.btn--success--solid {
    background-color: var(--success);
    color: var(--white);
    border: none;
}

.btn--default--solid {
    background-color: var(--white);
    color: var(--dark);
    border: none;
    box-shadow: 0 4px 4px 0 var(--box-shadow);
}

.btn--transparent--solid {
    background-color: transparent;
    color: var(--white);
    border-style: solid;
    border-color: var(--white);
    /* box-shadow: 0 4px 4px 0 var(--box-shadow); */
}

/* Outlined */
.btn--primary--outline {
    background-color: transparent;
    border: 2px solid #FFF;
    color: #FFF;
    border-radius: 10px;
}

.btn--warning--outline {
    background-color: transparent;
    border: 2px solid var(--warning);
    color: var(--warning);
}

.btn--danger--outline {
    background-color: transparent;
    border: 2px solid var(--danger);
    color: var(--danger);
}

.btn--success--outline {
    background-color: transparent;
    border: 2px solid var(--success);
    color: var(--success);
}

.btn--edit--outline {
    background-color: transparent;
    border: 2px solid var(--white);
    color: var(--dark);
}

.btn--transparent--outline {
    background-color: transparent;
    border: none;
    color: var(--dark);
}

.btn--default--outline {
    background-color: transparent;
    border: none;
    color: var(--default-color);
}

/* .btn--default--outline svg {
    margin-right: 10px;
} */

.btn-container svg {
    margin-right: 10px;
}

.btn--theme--outline {
    background-color: var(--default-color);
    color: var(--white);
    border: none
        /* box-shadow: 0 4px 4px 0 var(--box-shadow); */
}

.btn--danger--noline {
    background-color: transparent;
    border: none;
    color: var(--danger);
}




/* Button Size */
.btn--small {
    padding: 5px 10px;
    font-size: 8px;
}

.btn--small-14px {
    /* padding: 5px 0px; */
    font-size: 14px;
}

.btn--medium {
    padding: 8px 10px;
    font-size: var(--fs-btn-default);
    font-weight: var(--fw-btn-default);
    text-align: left;
    /* margin-right: 10px; */
}

.btn--medium-14px {
    padding: 3px 50px;
    font-size: 14px;
}

.btn--large {
    padding: 15px 30px;
    font-size: 16px;
}

.btn--link {
    padding: 0px;
}

.reminder-btn .btn--link {
    color: #467FD0;
    text-decoration: underline;
}

.btn--link>* {
    margin: 5px;
}

/* Button Text */

.btntxt {
    font-weight: var(--fw-btn-default);
    /* border-radius: 10px; */
    cursor: pointer;
    transition: transform 0.3s ease;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.btntxt:hover {
    transform: translateY(-3px);
    color: var(--default-color);
}

.btntxt:active {
    border-color: transparent;
}



/* button group */
.btngrp {
    font-family: var(--font-style);
    /* font-weight: 400; */
    border-radius: 10px;
    cursor: pointer;
    transition: transform 0.3s ease;

}

.customButton {
    width: 50px;
    height: 50px;
    letter-spacing: 0.5px;
    background-color: #E5E5E5;
    color: #54595F;
    font-size: 30px;
    font-weight: bold;
    border: 1.5px solid #E5E5E5;
    border-radius: 10px;
    cursor: pointer;
}

.active {
    /* background-color: var(--white); */
    /* color: var(--default-color); */
}

.collapsible {
    transition: margin-left .5s;
    padding: 1px;
    transition: margin-left .5s;

}

.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    /* padding: 10px 15px; */
    border: none;
}

.openbtn:hover {
    background-color: #444;
}

.sidebar {
    width: 0;
    z-index: 1;
    background-color: #111;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.sidebar a:hover {
    color: #f1f1f1;
}

.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}


/* button number */
.btn-number-container {
    display: flex;
    flex-direction: row;
}

.btn-number-each-div {
    flex: 1;
    padding: 5px;
}

.btn-number-each-div:first-child {
    padding-left: 0;
}

.btn-number-each-div:last-child {
    padding-right: 0;
}


.btn-number-each {
    justify-content: center;
    background-color: #E5E5E5;
    /* padding: 5px; */
    border-radius: 5px;

}

.btn-number-each-text {
    justify-content: center;
    background-color: #E5E5E5;
    /* padding: 5px; */
    border-radius: 5px;

}

.btn--inv--paid--solid {
    background-color: var(--active);
    color: var(--white);
    border: none;
}

.btn--inv--unpaid--solid {
    background-color: var(--hold);
    color: var(--white);
    border: none;
}

.btn--inv--stage--solid {
    background-color: var(--primary);
    color: var(--white);
    border: none;
}

.btn-number-each button {
    /* flex: 1; */
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    border-radius: 5px;
    font-weight: bold;
    font-size: 16px;
    padding: 5px;
}

.btn-number-each-text button {
    /* flex: 1; */
    width: 100%;
    cursor: pointer;
    transition: transform 0.3s ease;
    background-color: transparent;
    padding: 5px;
    color: #E5E5E5;
}